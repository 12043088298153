import React from 'react';
import { WidgetType } from 'src/enum';
import LeadFormPreviewPage from './LeadFormPreview';

/**
 * Review widget settings
 */

export default function WidgetPreviews({ widgetType }: { widgetType: WidgetType }) {
  switch (widgetType) {
    case WidgetType.LEAD_FORM:
      return <LeadFormPreviewPage />;

    default:
      console.error(`type=${widgetType}: Invalid widget type`);

      return <div>Invalid widget type</div>;
  }
}
