import { SidebarTrigger } from 'src/components/ui/sidebar';
import { Separator } from 'src/components/ui/separator';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from 'src/components/ui/breadcrumb';
import TeamContainer from 'src/layouts/dashboard/header/TeamContainer';
import useResponsive from 'src/hooks/useResponsive';
import NotificationsPopover from 'src/layouts/dashboard/header/NotificationsPopover';
import LanguagePopover from 'src/layouts/dashboard/header/LanguagePopover';
import { useSelector } from 'src/redux/store';
import { selectPlatformDisplayName } from 'src/redux/selectors/platform';
import { useLocation } from 'react-router';
import { data } from '../sidebar/appSidebar';
import { useTranslation } from 'react-i18next';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Platforms } from 'src/redux/slices/platform';
import Slug from 'src/layouts/dashboard/header/Slug';
import { selectHasPersonalWorkspace } from 'src/redux/slices/userSlice';
import Promotion from '../promotion';

const dashboardPaths = [
  ...data(Platforms.SIMPLY_REVIEW).navMain[0].items,
  ...data(Platforms.SIMPLY_REVIEW).navMain[1].items,
  ...data(Platforms.SIMPLY_REVIEW).navMain[2].items,
  {
    url: PATH_DASHBOARD.user.account,
    title: 'general.settings',
  },
];

export default function Header() {
  const { t } = useTranslation();
  const isDesktop = useResponsive('up', 'lg');
  const platformDisplayName = useSelector(selectPlatformDisplayName);
  // get url to match with the current page
  const hasPersonalWorkspace = useSelector(selectHasPersonalWorkspace);
  const { pathname } = useLocation();
  const path = dashboardPaths.find((item: any) => item.url === pathname);
  return (
    <header className="flex h-16 shrink-0 items-center gap-2 border-b px-4 justify-between z-20 bg-white">
      <div className="flex items-center gap-2">
        <SidebarTrigger className="-ml-1" />
        <Separator orientation="vertical" className="mr-2 h-4" />
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem className="hidden md:block">
              <BreadcrumbLink href="#">{platformDisplayName}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="hidden md:block" />
            <BreadcrumbItem>
              <BreadcrumbPage>{t(path?.title as string)}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="flex items-center gap-2">
        <Promotion />
        {hasPersonalWorkspace && <Slug />}
        {/* {hasPersonalWorkspace && isDesktop && <TeamContainer />} */}
        <LanguagePopover />
        {/* {isDesktop && <News />} */}
        <NotificationsPopover />
        {/* <AccountPopover /> */}
      </div>
    </header>
  );
}
