import website from 'src/assets/widget_onboarding/placeholder_web_lead.png';
import LeadFormPreview from 'src/pages/simplyleads/leadEmbedOnWebsite/Preview';

export default function LeadFormPreviewPage() {
  return (
    <div
      className={`flex h-full fixed !bg-neutral-100 mt-[-63px] pt-32 z-0`}
      style={{
        width: '-webkit-fill-available',
        background: `url(${website})`,
        backgroundSize: 'contain',
        // filter: isDarkMode ? 'invert(1)' : 'invert(0)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundOrigin: 'content-box',
      }}
    >
      <div className="absolute flex justify-center w-full top-14 -ml-24">
        <div style={{ transform: 'scale(0.40)' }}>
          <LeadFormPreview />
        </div>
      </div>
    </div>
  );
}
