import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';

// ----------------------------------------------------------------------

interface Props {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
  className?: string;
}
const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | Simply`}</title>
      {meta}
    </Helmet>

    <div ref={ref} {...other}>
      {children}
    </div>
  </>
));

export default Page;
