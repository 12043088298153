import { ReviewType } from 'src/enum';
import { SplitScreen } from 'src/components/split-screen';
import WidgetSettings from './WidgetSettings';
import WidgetPreviews from './WidgetPreviews';

export default function EditWidget({ widgetId }: any) {
  return (
    <SplitScreen
      direction="horizontal"
      leftDefaultSize={60}
      leftPanel={
        <div className="flex h-[100vh] justify-start p-6">
          <div className="w-full">
            <WidgetSettings widgetId={widgetId} />
          </div>
        </div>
      }
      rightDefaultSize={40}
      rightPanel={<WidgetPreviews widgetId={widgetId} />}
    />
  );
}

