import type * as React from 'react';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from 'src/components/ui/resizable';

interface SplitScreenProps extends React.ComponentProps<typeof ResizablePanelGroup> {
  leftPanel: React.ReactNode;
  rightPanel: React.ReactNode;
  leftDefaultSize?: number;
  rightDefaultSize?: number;
}

export function SplitScreen({
  leftPanel,
  leftDefaultSize = 50,
  rightPanel,
  rightDefaultSize = 50,
  ...props
}: SplitScreenProps) {
  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel defaultSize={leftDefaultSize}>{leftPanel}</ResizablePanel>
      {/* <ResizableHandle withHandle /> */}
      <ResizablePanel defaultSize={rightDefaultSize} minSize={30}>{rightPanel}</ResizablePanel>
    </ResizablePanelGroup>
  );
}
