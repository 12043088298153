import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Functions } from 'src/contexts/FirebaseContext';
import { useSelector } from 'src/redux/store';
import { Button } from 'src/components/ui/button';
import { X } from 'lucide-react';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';
import { toast } from 'sonner';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'src/components/ui/sheet';
import { Input } from 'src/components/ui/input';
import { PrefixedInput } from 'src/components/PrefixInput';
import { Form, FormControl, FormField, FormItem, FormMessage } from 'src/components/ui/form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Card } from 'src/components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { Ratings } from '@simplylabs_/shared';
import TrustPilot from 'src/assets/Trustpilot';
import Google from 'src/assets/Google';
import { httpsCallable } from 'firebase/functions';
import { Skeleton } from 'src/components/ui/skeleton';

type TrustPilotParams = { url: string };
type GoogleReviewBusinessParams = { business: string };

type ImportType = {
  content_type: 'text' | 'video';
  source: 'trustpilot' | 'google';
  platform_id: string;
  date: string;
  content: {
    title: string;
    text: string;
    media: any[];
    url: string;
    rating: number;
    vanityMetrics: any;
    customer_name: string;
    customer_avatar: string;
  };
};

function getText(source: 'trustpilot' | 'google' | null) {
  switch (source) {
    case 'trustpilot':
      return 'reviews.import.drawerSubtitleTrustpilotImportFrom';
    case 'google':
      return 'reviews.import.drawerSubtitleGoogleImportFrom';
    default:
      return '';
  }
}

export default function ReviewImportDrawer() {
  const workspaceId = useSelector(selectCurrentWorkspaceId);

  const { t } = useTranslation();

  const [selected, setSelected] = useState<'trustpilot' | 'google' | null>(null);
  const [reviews, setReviews] = useState<ImportType[]>([]);
  const [trustpilot, setTrustpilot] = useState<{ url: string } | {}>({});
  const [business, setBusiness] = useState<BusinessType | null>(null);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingReviews, setLoadingReviews] = useState(false);

  function resetState() {
    setSelected(null);
    setReviews([]);
    setOpen(false);
  }

  function getPayload() {
    if (selected === 'trustpilot') {
      return {
        trustpilot,
      };
    }
    if (selected === 'google') {
      return {
        google: business,
      };
    }
    return null;
  }

  const importAllReviews = async () => {
    try {
      if (!workspaceId) return toast.error(t('general.error'));
      if (reviews.length < 1) return toast.error(t('No reviews found'));
      const payload = getPayload();
      if (!payload) return toast.error(t('general.error'));
      setloading(true);
      const functionRef = httpsCallable(Functions, 'settings');
      const response: any = await functionRef({
        request: 'IMPORT_REVIEWS_JOB',
        workspaceId,
        type: selected,
        ...payload,
      });
      const error = response?.data?.httpErrorCode?.status || response?.data?.errorInfo?.code;

      if (error) {
        toast.error(
          response?.data?.errorInfo?.message || response?.data?.httpErrorCode?.canonicalName
        );
        return;
      }

      resetState();
      toast.success(t('All reviews imported'));
    } catch (error) {
      console.error(error);
      toast.error(t('general.error'));
    } finally {
      setloading(false);
    }
  };

  const description = getText(selected);

  const hasSelection = Boolean(selected);
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button>{t('reviews.import.drawerOpenBtn')}</Button>
      </SheetTrigger>
      <SheetContent
        className={`flex gap-0 overflow-hidden transition-all duration-300 sm:max-w-screen-xl ${
          hasSelection ? 'w-[1100px]' : 'w-[348px]'
        }`}
      >
        <div className="w-[300px] flex-shrink-0">
          <SheetHeader>
            <SheetTitle>{t('reviews.import.drawerTitle')}</SheetTitle>
            <SheetDescription>{t('reviews.import.drawerSubtitle')}</SheetDescription>
          </SheetHeader>
          <div className="grid gap-4 py-4">
            <Button
              variant={selected !== 'trustpilot' ? 'outline' : 'default'}
              onClick={() => setSelected('trustpilot')}
            >
              {t('reviews.import.trustpilot')}
            </Button>
            <Button
              variant={selected !== 'google' ? 'outline' : 'default'}
              onClick={() => setSelected('google')}
            >
              {t('reviews.import.googleReviews')}
            </Button>
          </div>
        </div>
        <div className={`w-[1px] h-full bg-gray-200 ml-6 mr-6 shadow-lg`} />

        <div className={`w-[700px] flex-shrink-0`}>
          <SheetHeader>
            <SheetTitle>
              {t('reviews.import.drawerTitleImportFrom')} {selected}
            </SheetTitle>
            <SheetDescription>{t(description)}</SheetDescription>
          </SheetHeader>
          <div className="grid gap-2 py-4">
            {selected === 'trustpilot' && (
              <TrustPilotReviewForm
                setReviews={setReviews}
                setLoadingReviews={setLoadingReviews}
                setTrustpilot={setTrustpilot}
              />
            )}
            {selected === 'google' && (
              <GoogleReviewFindBusinessForm
                setReviews={setReviews}
                setLoadingReviews={setLoadingReviews}
                business={business}
                setBusiness={setBusiness}
              />
            )}
          </div>
          {reviews.length > 0 && (
            <div className="pb-4">
              <Button className="w-full" onClick={importAllReviews} loading={loading}>
                {t('reviews.import.importBtn')}
              </Button>
              <p className="text-sm text-gray-400 mt-2">
                {t('reviews.import.importBtnDescription')}
                😁
              </p>
            </div>
          )}
          <ScrollArea className="h-[550px]">
            <div className="columns-2 gap-4 space-y-4 py-4 pb-8">
              {loadingReviews && [1, 2, 3, 4, 5, 6].map((_, index) => <SkeletonCard key={index} />)}
              {!loadingReviews &&
                reviews.map((review) => (
                  <Card
                    key={review.platform_id}
                    className="break-inside-avoid p-4 space-y-2 shadow-none"
                  >
                    <div className="flex gap-2 items-center justify-between">
                      <div className="flex gap-2 items-center">
                        <Avatar className="w-6 h-6">
                          <AvatarImage
                            src={review.content.customer_avatar || undefined}
                            alt={review.content.customer_name}
                          />
                          <AvatarFallback>{review.content.customer_name[0]}</AvatarFallback>
                        </Avatar>
                        <p className="text-sm">{review.content.customer_name}</p>
                      </div>
                      <div>{getSourceIcon(review.source)}</div>
                    </div>
                    <div>
                      <Ratings size="small" rating={review.content.rating} />
                    </div>
                    <div className="">
                      <p className="text-sm font-bold">{review.content.title}</p>
                    </div>
                    <div>
                      <p className="text-sm ">{review.content.text}</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray-400">
                        {t('general.dateShortYear', { date: new Date(review.date) })}
                      </p>
                    </div>
                  </Card>
                ))}
            </div>
          </ScrollArea>
          {/* <SheetFooter></SheetFooter> */}
        </div>
      </SheetContent>
    </Sheet>
  );
}

export function getSourceIcon(source?: 'trustpilot' | 'google') {
  switch (source) {
    case 'trustpilot':
      return <TrustPilot />;
    case 'google':
      return <Google />;
    default:
      return null;
  }
}

function TrustPilotReviewForm({
  setReviews,
  setLoadingReviews,
  setTrustpilot,
}: {
  setReviews: (reviews: ImportType[]) => void;
  setLoadingReviews: (loading: boolean) => void;
  setTrustpilot: (trustpilot: { url: string }) => void;
}) {
  const { t } = useTranslation();
  const ImportTrustPilotReview = z.object({
    url: z.string(),
  });
  const form = useForm<TrustPilotParams>({
    resolver: zodResolver(ImportTrustPilotReview),
  });
  const {
    // watch,
    // setValue,
    handleSubmit,
    // reset,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data: any) => {
    try {
      // await fakeApiCall();
      setLoadingReviews(true);

      // setReviews(dummyReviews);

      const result = await axios.get(
        `https://engine.senja.io/curator/integrations/trustpilot/search?id=${data.url}&limit=5&offset=1`
      );
      if (!result?.data || result?.data?.length < 1 || result.status !== 200) {
        console.error(result.status);
        return toast.error(t('reviews.import.noReviewsFound'));
      }
      setTrustpilot({ url: data.url });
      setReviews(result.data);
      toast.success(t(`reviews.import.reviewsFound`, { reviews: result.data.length }));
    } catch (error) {
      console.error(error);
      toast.error(t('general.error'));
    } finally {
      setLoadingReviews(false);
    }
  };
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex gap-2">
        <FormField
          control={form.control}
          name="url"
          render={({ field }) => (
            <FormItem className="flex flex-col w-full">
              <FormControl>
                <PrefixedInput
                  prefix="https://www.trustpilot.com/review/"
                  placeholder={t('reviews.import.trustpilotPagePlaceholder') as string}
                  {...field}
                />
              </FormControl>
              {/* <FormDescription>This is your public display name.</FormDescription> */}
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-end">
          <Button type="submit" loading={isSubmitting}>
            {t('reviews.import.findReviewsBtn')}
          </Button>
        </div>
      </form>
    </Form>
  );
}

type BusinessType = {
  id: string;
  name: string;
  place_id: string;
  review_count: number;
  rating: number;
  website: string;
};

function GoogleReviewFindBusinessForm({
  business,
  setBusiness,
  setReviews,
  setLoadingReviews,
}: {
  business: BusinessType | null;
  setBusiness: (business: BusinessType | null) => void;
  setReviews: (reviews: ImportType[]) => void;
  setLoadingReviews: (loading: boolean) => void;
}) {
  const { t } = useTranslation();

  const [results, setResults] = useState<BusinessType[]>([]);
  const GoogleReviewBusines = z.object({
    business: z.string(),
  });
  const form = useForm<GoogleReviewBusinessParams>({
    resolver: zodResolver(GoogleReviewBusines),
  });
  const {
    // watch,
    // setValue,
    handleSubmit,
    // reset,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data: any) => {
    try {
      const result = await axios.get(
        `https://engine.senja.io/curator/integrations/google/profiles?query=${data.business}`
      );
      if (!result?.data || result?.data?.length < 1 || result.status !== 200) {
        console.error(result.status);
        return toast.error(t('reviews.import.noBusinessFound'));
      }
      setResults(result.data);
      console.log(result.data);
      toast.success(t(`reviews.import.businessFound`, { matches: result.data.length }));
    } catch (error) {
      console.error(error);
      toast.error(t('general.error'));
    }
  };

  useEffect(() => {
    console.log({ business });

    if (!business?.id || !business?.place_id) return;
    onGetReviews();
  }, [business?.id]);

  const onGetReviews = async () => {
    try {
      setLoadingReviews(true);

      if (!business?.id || !business?.place_id) {
        throw new Error('Business id or place_id not found');
      }

      // await fakeApiCall();

      const result = await axios.get(
        `https://engine.senja.io/curator/integrations/google/search?google_id=${business.id}&place_id=${business.place_id}&limit=20`
      );
      if (!result?.data || result?.data?.length < 1 || result.status !== 200) {
        console.error(result.status);
        return toast.error(t('reviews.import.noReviewsFound'));
      }
      setReviews(result.data);
      // console.log(result.data);
      toast.success(t(`reviews.import.reviewsFound`, { reviews: result.data.length }));
    } catch (error) {
      console.error(error);
      toast.error(t('general.error'));
    } finally {
      setLoadingReviews(false);
    }
  };

  const onBusinessClick = (business: BusinessType | null) => {
    setBusiness(business);
  };

  if (business) {
    return (
      <div className="flex items-center gap-2">
        <Card
          key={business.id}
          className="p-2 space-y-2 w-full shadow-none cursor-pointer bg-slate-100"
          onClick={() => onBusinessClick(null)}
        >
          <div className="flex gap-2 items-center justify-between">
            <div className="flex gap-2 items-center">
              <Avatar className="w-6 h-6">
                <AvatarFallback>{business.name[0]}</AvatarFallback>
              </Avatar>
              <p className="text-sm">{business.name}</p>
            </div>
            <div className="flex gap-1 items-center">
              <div>
                <p className="text-sm font-bold">{business.rating} stars</p>
              </div>
              <div>
                <p className="text-sm ">from {business.review_count} reviews</p>
              </div>
              <div className="ml-4">
                <X size={16} />
              </div>
            </div>
          </div>
        </Card>
        {/* <Button loading={loadingReviews} onClick={onGetReviews}>
          Find reviews
        </Button> */}
      </div>
    );
  }
  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex gap-2">
          <FormField
            control={form.control}
            name="business"
            render={({ field }) => (
              <FormItem className="flex flex-col w-full">
                <FormControl>
                  <Input
                    placeholder={t('reviews.import.googlePagePlaceholder') as string}
                    {...field}
                  />
                </FormControl>
                {/* <FormDescription>This is your public display name.</FormDescription> */}
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-end">
            <Button type="submit" loading={isSubmitting}>
              {t('reviews.import.findBusinessBtn')}
            </Button>
          </div>
        </form>
      </Form>
      <ScrollArea className="h-[600px]">
        <div className="gap-2 space-y-2">
          {results.map((business) => (
            <Card
              key={business.id}
              className="p-4 space-y-2 shadow-none cursor-pointer"
              onClick={() => onBusinessClick(business)}
            >
              <div className="flex gap-2 items-center justify-between">
                <div className="flex gap-2 items-center">
                  <Avatar className="w-8 h-8">
                    <AvatarFallback>{business.name[0]}</AvatarFallback>
                  </Avatar>
                  <p className="text-sm">{business.name}</p>
                </div>
                <div className="flex gap-1 items-center">
                  <div>
                    <p className="text-sm font-bold">{business.rating} stars</p>
                  </div>
                  <div>
                    <p className="text-sm ">from {business.review_count} reviews</p>
                  </div>
                </div>
              </div>
              <div>
                <p className="text-sm text-gray-400">{business.website}</p>
              </div>
            </Card>
          ))}
        </div>
      </ScrollArea>
    </>
  );
}

function SkeletonCard() {
  return (
    <div className="flex flex-col space-y-3">
      <Skeleton className="h-[125px] w-full rounded-xl" />
      <div className="space-y-2">
        <Skeleton className="h-4 w-4/5" />
        <Skeleton className="h-4 w-3/4" />
      </div>
    </div>
  );
}
