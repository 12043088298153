import { Box, Grid, Radio, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { Card } from 'src/components/ui/card';
import { Checkbox } from 'src/components/ui/checkbox';

type Props = {
  isLightMode: boolean;
  isDarkMode: boolean;
  setLightMode: () => void;
  setDarkMode: () => void;
  children: JSX.Element;
  childrenDark: JSX.Element;
};

export default function Customise({
  isLightMode,
  isDarkMode,
  setLightMode,
  setDarkMode,
  children,
  childrenDark,
}: Props) {
  const theme = useTheme();
  return (
    <Grid container spacing={3}>
      <Grid xs={12} sm={6} item sx={{ position: 'relative' }}>
        <Card
          className="relative cursor-pointer overflow-hidden shadow-none hover:bg-neutral-50 transition-colors duration-150"
          // sx={{ mt: 2, pr: 4, cursor: 'pointer' }}
          onClick={setLightMode}
        >
          <Box
            sx={{
              position: 'absolute',
              borderBottom: `1px solid ${theme.palette.grey[200]}`,
              width: '100%',
              height: 136,
            }}
          />
          <Box
            sx={{
              p: 0,
              pt: 4,
              pb: 2,
              ml: '50%',
              width: '100%',
              transform: 'scale(1.4)',
            }}
          >
            {children}
          </Box>
          <div className="flex items-center px-6 pt-10 pb-4">
            <Checkbox id="lightmode" className="shadow-none" checked={isLightMode} />
            <label htmlFor="lightmode" className="ml-2 text-sm">
              Light mode
            </label>
          </div>
        </Card>
      </Grid>
      <Grid xs={12} sm={6} item sx={{ position: 'relative' }}>
        <Card
          className="relative cursor-pointer overflow-hidden shadow-none hover:bg-neutral-50 transition-colors duration-150"
          // sx={{ mt: 2, pr: 4, cursor: 'pointer' }}
          onClick={setDarkMode}
        >
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: '#000',
              width: '100%',
              height: 136,
            }}
          />
          <Box
            sx={{
              p: 0,
              pt: 4,
              pb: 2,
              ml: '50%',
              width: '100%',
              transform: 'scale(1.4)',
            }}
          >
            {childrenDark}
          </Box>

          <div className="flex items-center px-6 pt-10 pb-4">
            <Checkbox id="darkmode" className="shadow-none" checked={isDarkMode} />
            <label htmlFor="darkmode" className="ml-2 text-sm">
              Dark mode
            </label>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}
