import { MiniReviewCount } from '@simplylabs_/shared';
import website from 'src/assets/widget_onboarding/placeholder_web.png';
import { Card } from 'src/components/ui/card';
import { WidgetType } from 'src/enum';
import { selectCurrentPlatformIntegrationsMap } from 'src/redux/selectors/integrations';
import { useSelector } from 'src/redux/store';
import { WidgetTypeConfig } from 'src/types';

export default function MicroStarCountPreview({ widgetId }: { widgetId: string }) {
  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  const widgetConfig = integrationMap[widgetId]?.config as WidgetTypeConfig<WidgetType.MICRO_STARS>;
  const isDarkMode = widgetConfig?.textColor === '#ffffff';
  return (
    <div
      className={`flex h-full justify-center p-6 relative ${
        isDarkMode ? '!bg-neutral-900' : '!bg-neutral-100'
      }`}
      style={{
        background: `url(${website})`,
        // backgroundSize: 'contain',
        filter: isDarkMode ? 'invert(1)' : 'invert(0)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundOrigin: 'content-box',
      }}
    >
      <div className="absolute w-[600px] top-[40%]">
        <div>
          <Card
            className={`px-0 p-2 shadow-none transition-colors duration-1500 ${
              isDarkMode ? 'bg-neutral-900' : 'bg-white'
            }`}
            style={{
              transform: 'scale(0.7)',
              filter: isDarkMode ? 'invert(1)' : 'invert(0)',
              backgroundColor: widgetConfig?.textColor === '#ffffff' ? 'black' : 'white',
            }}
          >
            <MiniReviewCount
              textColor={widgetConfig?.textColor || '#000000'}
              reviewCount={100}
              rating={4.5}
              slug="simply-review"
            />
          </Card>
        </div>
      </div>
    </div>
  );
}
