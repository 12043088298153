import { Button } from '@relume_io/relume-ui';
import type { ButtonProps } from '@relume_io/relume-ui';
import { useEditor } from '../../editor-context';
import { useTheme } from '../../ThemeProvider';
import { getContrastColor } from '@simplylabs_/shared';

type ImageProps = {
  src: string;
  alt?: string;
};

type Props = {
  headline: string;
  bodyText: string;
  buttons: ButtonProps[];
  image: ImageProps;
};

export type Header5Props = React.ComponentPropsWithoutRef<'section'> & Partial<Props>;

export const HeroLayout1 = (props: Header5Props) => {
  const { sections, selectedSectionId, selectSection } = useEditor();
  const { theme } = useTheme();
  const backgroundColor = theme.colors.main;
  const headingFont = theme.fonts.heading;
  const bodyFont = theme.fonts.body;
  const textColor = getContrastColor(backgroundColor, true);
  const { headline, bodyText, buttons, image } = {
    ...sections.hero.content,
    ...props,
  } as Props;

  const edgeConfig = theme.borderRadius === 'soft' ? 'rounded-lg' : 'rounded-none';
  return (
    <section id="relume" className="relative px-[5%]" style={{ backgroundColor }}>
      <div className="container relative z-10">
        <div className="flex max-h-[60rem] min-h-svh items-center py-16 md:py-24 lg:py-28">
          <div className="flex flex-col self-end max-w-md">
            <h1
              className="mb-5 text-6xl font-bold text-text-alternative md:mb-6 md:text-9xl lg:text-10xl"
              style={{
                // color: textColor,
                fontFamily: headingFont.name,
                fontWeight: headingFont.style,
              }}
            >
              {headline}
            </h1>
            <p
              className="text-text-alternative md:text-md"
              style={{
                // color: textColor,
                fontFamily: bodyFont.name,
                fontWeight: bodyFont.style,
              }}
            >
              {bodyText}
            </p>
            <div className="mt-6 flex flex-wrap gap-4 md:mt-8">
              {/* {buttons.map((button, index) => (
                <Button key={index} {...button}>
                  {button.title}
                </Button>
              ))} */}
            </div>
          </div>
        </div>
      </div>
      <div className={`absolute inset-3 z-0`}>
        <img
          src={
            'https://images.unsplash.com/photo-1534258936925-c58bed479fcb?q=80&w=3131&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
            // image.src
          }
          className={`size-full object-cover ${edgeConfig}`}
          alt={image.alt}
        />
        <div className={`absolute inset-0 bg-black/50 ${edgeConfig}`} />
      </div>
    </section>
  );
};

export const Header5Defaults: Props = {
  headline: 'Medium length hero heading goes here',
  bodyText:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
  buttons: [{ title: 'Button' }, { title: 'Button', variant: 'secondary-alt' }],
  image: {
    src: 'https://d22po4pjz3o32e.cloudfront.net/placeholder-image.svg',
    alt: 'Relume placeholder image',
  },
};
