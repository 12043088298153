import React, { useState } from 'react';
import { HeroLayout2 } from './sections/Heros/Layout2';
import { RotationLogo } from './sections/RotationLogos';
import { FeatureBlock } from './sections/FeatureBlock';
import { Footer } from './sections/Footer';
import { Navbar } from './sections/Navbar';
import Page from 'src/components/Page';
import { SplitScreen } from 'src/components/split-screen';
import SectionEditor from './SectionEditor';
import { useEditor } from './editor-context';
import { Button } from 'src/components/ui/button';
import { EyeOff } from 'lucide-react';
import { Layout1 } from './sections/Layouts/Layout1';
import { Layout201 } from './sections/Layouts/Layout201';
import { Pricing20 } from './sections/Layouts/Pricing20';
import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { PreviewIframe } from './PreviewIframe';
import { HeroLayout1 } from './sections/Heros/Layout1';

export default function Preview() {
  const { selectedSectionId, selectSection } = useEditor();
  const [viewport, setViewport] = useState<'web' | 'mobile'>('web');
  return (
    <div
      className="px-6 py-2"
      style={{
        backgroundColor: 'rgba(255,255,0,0.1)',
        height: 'calc(100vh - 64px)',
        overflowY: 'scroll',
      }}
    >
      <div className="py-4">
        <Tabs value={viewport} onValueChange={(value) => setViewport(value as any)}>
          <TabsList className="grid grid-cols-2 w-1/2 h-full">
            <TabsTrigger value="web" className="">
              Web
            </TabsTrigger>
            <TabsTrigger value="mobile" className="">
              Mobile
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      {/* <PreviewIframe style={{ height: '100%', width: '100%' }}> */}
      <div
        style={{
          backgroundColor: '#fff',
          position: 'relative',
          zoom: 0.5,
          borderRadius: '8px',
          // boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* <ActiveSection
            isSelected={selectedSectionId === 'navbar'}
            key="navbar"
            onClick={() => selectSection('navbar')}
          >
            <Navbar />
          </ActiveSection> */}

        <ActiveSection
          isSelected={selectedSectionId === 'hero'}
          key="hero"
          onClick={() => selectSection('hero')}
        >
          {/* <HeroLayout2 /> */}
          <HeroLayout1 />
        </ActiveSection>
        {/* <ActiveSection
                isSelected={selectedSectionId === 'logos'}
                key="logos"
                onClick={() => selectSection('logos')}
              >
                <RotationLogo />
              </ActiveSection> */}
        <ActiveSection
          isSelected={selectedSectionId === 'layout1'}
          key="layout1"
          onClick={() => selectSection('layout1')}
        >
          <Layout1 />
        </ActiveSection>
        <ActiveSection
          isSelected={selectedSectionId === 'layout201'}
          key="layout201"
          onClick={() => selectSection('layout201')}
        >
          <Layout201 />
        </ActiveSection>
        <ActiveSection
          isSelected={selectedSectionId === 'pricing20'}
          key="pricing20"
          onClick={() => selectSection('pricing20')}
        >
          <Pricing20 />
        </ActiveSection>
        {/* <ActiveSection
          isSelected={selectedSectionId === 'footer'}
          key="footer"
          onClick={() => selectSection('footer')}
        >
          <Footer />
        </ActiveSection> */}
      </div>
      {/* </PreviewIframe> */}
    </div>
  );
}

function ActiveSection({
  isSelected,
  children,
  onClick,
}: {
  isSelected: boolean;
  children: React.ReactNode;
  onClick: () => void;
}) {
  const { tab } = useEditor();

  const isActive = tab !== 'editor' || isSelected;

  //   const [isHovering, setisHovering] = useState(false);
  //   const [hidden, setHidden] = useState(false);
  return (
    <div
      role="button"
      onClick={onClick}
      //   onMouseEnter={() => setisHovering(true)}
      //   onMouseLeave={() => setisHovering(false)}
      style={{
        // overflow: hidden ? 'hidden' : 'visible',
        // height: hidden ? 100 : 'auto',
        transition: 'transform 0.3s',
        position: 'relative',
        opacity: isActive ? 1 : 0.5,
        // transform: isSelected ? 'scale(1.01)' : 'scale(1)',
        cursor: 'pointer',
        // border: isActive ? '2px solid #000' : '2px solid transparent',
        // backgroundColor: isSelected ? 'rgba(0,0,0,0.1)' : 'transparent',
      }}
    >
      {children}
      {/* {(isHovering || isSelected) && (
        <div className="absolute top-4 right-4 scale-150">
          <Button size="icon" variant="outline" onClick={() => setHidden((prev) => !prev)}>
            <EyeOff />
          </Button>
        </div>
      )} */}
    </div>
  );
}
