'use client';

import { createContext, useContext, useState, type ReactNode } from 'react';

type Section = {
  id: string;
  name: string;
  content: {
    headline?: string;
    bodyText?: string;
    buttonText?: string;
    image?: {
      src: string;
      alt: string;
    } | null;
  };
};

type SectionsMap = { [key: string]: Section };

type EditorContextType = {
  sections: SectionsMap;
  selectedSectionId: string | null;
  updateSection: (sectionId: string, content: Partial<Section['content']>) => void;
  selectSection: (sectionId: string) => void;
  tab: TabType;
  setTab: (tab: TabType) => void;
};

const initialSections: SectionsMap = {
  hero: {
    id: 'hero',
    name: 'Hero Section',
    content: {
      headline: 'Transform your body, Empower your mind',
      bodyText:
        'Your journey to strength, confidence, and lasting health starts today. Personalized fitness and nutrition coaching designed just for you.',
      buttonText: 'Button',
      image: {
        src: 'https://d22po4pjz3o32e.cloudfront.net/placeholder-image.svg',
        alt: 'Relume placeholder image',
      },
    },
  },
  logos: {
    id: 'logos',
    name: 'Logo Section',
    content: {
      headline: "Used by the world's most average companies",
    },
  },
  feature: {
    id: 'feature',
    name: 'Feature Section',
    content: {
      headline: 'HEJ heading is what you see here in this feature section',
      bodyText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
      image: null,
    },
  },
};

const EditorContext = createContext<EditorContextType | null>(null);

export type TabType = 'editor' | 'brand' | 'settings';

export function EditorProvider({ children }: { children: ReactNode }) {
  const [sections, setSections] = useState<SectionsMap>(initialSections);
  const [selectedSectionId, setSelectedSectionId] = useState<string | null>('hero');
  const [tab, setTab] = useState<TabType>('editor');

  const updateSection = (sectionId: string, content: Partial<Section['content']>) => {
    setSections((prev) => ({
      ...prev,
      [sectionId]: {
        ...prev[sectionId],
        content: { ...prev[sectionId].content, ...content },
      },
    }));
  };

  const selectSection = (sectionId: string) => {
    setSelectedSectionId(sectionId);
  };

  return (
    <EditorContext.Provider
      value={{ sections, selectedSectionId, updateSection, selectSection, tab, setTab }}
    >
      {children}
    </EditorContext.Provider>
  );
}

export function useEditor() {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error('useEditor must be used within an EditorProvider');
  }
  return context;
}
