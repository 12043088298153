import { FlowVideo, Position } from '@simplylabs_/shared';
import website from 'src/assets/widget_onboarding/placeholder_web.png';
import { WidgetType } from 'src/enum';
import { selectCurrentPlatformIntegrationsMap } from 'src/redux/selectors/integrations';
import { useSelector } from 'src/redux/store';
import { WidgetTypeConfig } from 'src/types';

export default function FloatingVideoPreview({ widgetId }: { widgetId: string }) {
  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  const widget = integrationMap[widgetId];
  const widgetConfig = integrationMap[widgetId]
    ?.config as WidgetTypeConfig<WidgetType.FLOATING_INTRO_VIDEO>;
  //   const isDarkMode = widgetConfig?.textColor === '#ffffff';

  // @ts-ignore
  const isVideoReady = widget?.videoProcessed === true;
  console.log({ integrationMap, isVideoReady });
  return (
    <div
      className={`flex h-full fixed !bg-neutral-100 mt-[-63px] pt-32 z-0`}
      style={{
        width: '-webkit-fill-available',
        background: `url(${website})`,
        // backgroundSize: 'contain',
        // filter: isDarkMode ? 'invert(1)' : 'invert(0)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundOrigin: 'content-box',
      }}
    >
      <div
        style={{
          position: 'absolute',
          right: widgetConfig?.position !== Position.bottomLeft ? 50 : 'inherit',
          left: widgetConfig?.position !== Position.bottomRight ? 50 : 'inherit',
          bottom: 10,
        }}
      >
        {widgetConfig && isVideoReady && (
          <FlowVideo
            token="test-token"
            videoPlaybackId={widgetConfig?.videoPlaybackId}
            position={widgetConfig?.position}
            aspectRatio={widgetConfig?.aspectRatio}
            gifFrame={widgetConfig?.gifFrame}
            height={250}
            thumbFrame={widgetConfig?.thumbFrame}
            // @ts-ignore
            buttonText={widgetConfig?.buttonText}
            // @ts-ignore
            buttonLink={widgetConfig?.buttonLink}
            // @ts-ignore
            buttonColor={widgetConfig?.buttonColor}
          />
        )}
      </div>
    </div>
  );
}
