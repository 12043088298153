import WidgetActivation from '../../Steps/components/WidgetActivation';
import WidgetCodeBlock from '../../Steps/components/WidgetCodeBlock';
import { ROOT_LEADS } from 'src/utils/getWidgetCode';
import VideoInstructionCard from '../../Steps/components/VideoInstructionCard';
// import SendInstructionCard from '../../Steps/components/SendInstructionCard';

export function getLeadFormWidgetCode(workspaceId: string, widgetId: string) {
  return `<script defer src="${ROOT_LEADS}/leadform/index.js"></script>
<div data-widget-leadform="${workspaceId}_${widgetId}"></div>`;
}

export default function LeadFormSettings({
  workspaceId,
  widgetId,
}: {
  workspaceId: string;
  widgetId: string;
}) {
  const code = getLeadFormWidgetCode(workspaceId, widgetId);

  return (
    <div className="pt-6">
      <div className="p-2 pb-8">
        <WidgetCodeBlock code={code} />
      </div>
      <div className="p-2">
        <VideoInstructionCard tutorialVideo="https://storage.googleapis.com/simply-review-b6fb2.appspot.com/tutorials/setup-video-widget-short.mov" />
      </div>
      {/* <div className="p-2">
        <SendInstructionCard widgetId={widgetId} />
      </div> */}
      <div className="p-2 pt-8">
        <WidgetActivation widgetId={widgetId} />
      </div>
    </div>
  );
}
