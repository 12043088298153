import * as React from 'react';
import {
  ALargeSmall,
  AppWindow,
  ArrowDownCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Globe,
  Plus,
  ShapesIcon,
  Share,
  Shuffle,
  Square,
  Squircle,
  SwatchBookIcon,
} from 'lucide-react';
import { cn } from 'src/utils/utils';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'src/components/ui/collapsible';
import { Button } from 'src/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import img1 from './placeholders/img1.jpg';
import img2 from './placeholders/img2.jpg';
import img3 from './placeholders/img3.jpg';
import { useTheme } from './ThemeProvider';
import { FontStyle, Theme } from './theme';

export const colors = [
  { mainBg: '#111111', secondaryBg: '#ffffff', border: 'border-transparent' },
  { mainBg: '#2D0A31', secondaryBg: '#F5E1F7', border: 'border-transparent' },
  { mainBg: '#0A192F', secondaryBg: '#E6F1FF', border: 'border-transparent' },
  { mainBg: '#FFF1F1', secondaryBg: '#FF9494', border: 'border-transparent' },
  { mainBg: '#1C2B12', secondaryBg: '#E8F5E2', border: 'border-transparent' },
  { mainBg: '#3A2A1B', secondaryBg: '#F5EBE0', border: 'border-transparent' },
  { mainBg: '#0A1657', secondaryBg: '#C5C8FF', border: 'border-transparent' },
];
// Define font pairs (heading font, body font)
export const fontPairs: {
  heading: { name: string; style: FontStyle; family: string };
  body: { name: string; style: FontStyle; family: string };
}[] = [
  {
    heading: { name: 'Urbanist', style: '400', family: 'Urbanist, sans-serif' },
    body: { name: 'Roboto', style: '400', family: 'Roboto, sans-serif' },
  },
  {
    heading: { name: 'Playfair Display', style: '700', family: 'Playfair Display, serif' },
    body: { name: 'Source Sans Pro', style: '400', family: 'Source Sans Pro, sans-serif' },
  },
  {
    heading: { name: 'Montserrat', style: '600', family: 'Montserrat, sans-serif' },
    body: { name: 'Open Sans', style: '400', family: 'Open Sans, sans-serif' },
  },
  {
    heading: { name: 'Raleway', style: '800', family: 'Raleway, sans-serif' },
    body: { name: 'Lato', style: '400', family: 'Lato, sans-serif' },
  },
  {
    heading: { name: 'Poppins', style: '500', family: 'Poppins, sans-serif' },
    body: { name: 'Inter', style: '400', family: 'Inter, sans-serif' },
  },
];

export default function DesignSystem() {
  return (
    <div className="flex flex-col gap-4  max-w-3xl rounded-lg">
      <ColorPalette />
      <Typography />
      <Shapes />
      <Favicon />
    </div>
  );
}

function ColorPalette() {
  const [isOpen, setIsOpen] = React.useState(true);
  const { theme, setTheme } = useTheme();
  const colorIndex = colors.findIndex(
    (color) => color.mainBg === theme.colors.main && color.secondaryBg === theme.colors.secondary
  );

  const handleUpdateTheme = (swatch: { mainBg: string; secondaryBg: string }) => {
    setTheme((prev: Theme) => ({
      ...prev,
      colors: { ...prev.colors, main: swatch.mainBg, secondary: swatch.secondaryBg },
    }));
  };

  const renderColors = colors;

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="rounded-lg border bg-gray-100 border-gray-100  shadow-sm"
    >
      <CollapsibleTrigger className="flex w-full items-center justify-between p-4">
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2 bg-white p-2 rounded-lg">
            <SwatchBookIcon className="h-4 w-4 text-black" />
          </div>
          <span className="text-md font-medium">Color Palette</span>
        </div>
        <ChevronDown className={cn('h-5 w-5 transition-transform', isOpen ? 'rotate-180' : '')} />
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="flex flex-wrap gap-2 p-4 pt-0">
          {renderColors.map((color, index) => (
            <div
              role="button"
              key={index}
              className={cn(
                'relative h-8 w-16 rounded-md border cursor-pointer transition-all hover:opacity-90 border-white overflow-hidden p-0.5',
                color.border,
                colorIndex === index ? 'ring-1 ring-black' : ''
              )}
              onClick={() => {
                handleUpdateTheme(color);
              }}
            >
              <div className="flex h-full w-full overflow-hidden rounded-sm">
                <div className="h-full w-1/2" style={{ backgroundColor: color.mainBg }} />
                <div className="h-full w-1/2" style={{ backgroundColor: color.secondaryBg }} />
              </div>
            </div>
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}

function Typography() {
  const [isOpen, setIsOpen] = React.useState(true);
  const { theme, setTheme } = useTheme();
  const fontPairIndex = fontPairs.findIndex((f) => f.heading.name === theme.fonts.heading.name);
  const [isShuffling, setIsShuffling] = React.useState(false);

  const handleUpdateTheme = ({
    heading,
    body,
  }: {
    heading: Theme['fonts']['heading'];
    body: Theme['fonts']['heading'];
  }) => {
    setTheme((prev: Theme) => ({
      ...prev,
      fonts: { ...prev.colors, heading, body },
    }));
  };

  const handleShuffle = () => {
    setIsShuffling(true);
    // Move to the next font pair in the array
    const nextPair = fontPairs[(fontPairIndex + 1) % fontPairs.length];
    handleUpdateTheme({
      heading: {
        name: nextPair.heading.name,
        style: nextPair.heading.style as Theme['fonts']['heading']['style'],
      },
      body: {
        name: nextPair.body.name,
        style: nextPair.body.style as Theme['fonts']['heading']['style'],
      },
    });

    // Reset the shuffling animation after a short delay
    setTimeout(() => {
      setIsShuffling(false);
    }, 500);
  };

  const currentPair = fontPairs[fontPairIndex];

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="rounded-lg border border-gray-100 bg-gray-100 shadow-sm"
    >
      <CollapsibleTrigger className="flex w-full items-center justify-between p-4">
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2 bg-white p-2 rounded-lg">
            <ALargeSmall className="h-4 w-4 text-black" />
          </div>
          <span className="text-md font-medium">Typography</span>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="secondary"
            className="bg-black text-white hover:bg-black/90"
            onClick={(e) => {
              e.stopPropagation(); // Prevent collapsible from toggling
              handleShuffle();
            }}
          >
            <Shuffle
              className={cn('mr-2 h-4 w-4 transition-transform', isShuffling ? 'rotate-180' : '')}
            />
            Shuffle
          </Button>
          <ChevronDown className={cn('h-5 w-5 transition-transform', isOpen ? 'rotate-180' : '')} />
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="grid grid-cols-2 gap-4 p-4 pt-0">
          <div className="rounded-lg border border-gray-200 p-4 bg-white">
            <h4 className="text-sm font-medium text-gray-500">Heading</h4>
            <div className="mt-4 space-y-2">
              <p
                className="text-2xl font-normal"
                style={{ fontFamily: currentPair.heading.family }}
              >
                {currentPair.heading.name}{' '}
                <span className="text-gray-400">{currentPair.heading.style}</span>
              </p>
              <div className="flex items-center gap-2">
                <span className="font-medium">Google</span>
                <span className="text-gray-500">|</span>
                <span className="text-gray-500">9 styles</span>
                <span className="text-gray-500">|</span>
                <span className="text-gray-500">Free</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between rounded-lg border border-gray-200 p-4 bg-white">
            <h4 className="flex flex-grow text-sm font-medium text-gray-500">Body</h4>
            <div className="flex flex-col mt-4 space-y-2">
              <p className="text-2xl font-normal" style={{ fontFamily: currentPair.body.family }}>
                {currentPair.body.name}{' '}
                <span className="text-gray-400">{currentPair.body.style}</span>
              </p>
              <div className="flex items-center gap-2">
                <span className="font-medium">Google</span>
                <span className="text-gray-500">|</span>
                <span className="text-gray-500">9 styles</span>
                <span className="text-gray-500">|</span>
                <span className="text-gray-500">Free</span>
              </div>
            </div>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}

function Shapes() {
  const [isOpen, setIsOpen] = React.useState(true);

  const { theme, setTheme } = useTheme();

  const handleUpdateShape = (shape: 'soft' | 'squared') => {
    setTheme((prev: Theme) => ({
      ...prev,
      borderRadius: shape,
    }));
  };

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="rounded-lg border border-gray-100 bg-gray-100 shadow-sm"
    >
      <CollapsibleTrigger className="flex w-full items-center justify-between p-4">
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2 bg-white p-2 rounded-lg">
            <ShapesIcon className="h-4 w-4 text-black" />
          </div>
          <span className="text-md font-medium">Shapes</span>
        </div>
        <ChevronDown className={cn('h-5 w-5 transition-transform', isOpen ? 'rotate-180' : '')} />
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="p-4 pt-0">
          <div className="flex gap-4">
            <Tabs
              value={theme.borderRadius}
              // className="w-[400px]"
              onValueChange={(value) => {
                handleUpdateShape(value as 'soft' | 'squared');
              }}
            >
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="soft" className="gap-1">
                  <Squircle className="h-4 w-4" /> Soft edges
                </TabsTrigger>
                <TabsTrigger value="squared" className="gap-1">
                  <Square className="h-4 w-4" />
                  Squared
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>

          <div className="mt-4 grid grid-cols-2 gap-4">
            <div className="flex flex-col rounded-lg border border-gray-200 p-4 bg-white">
              <h4 className="mb-4 text-sm font-medium text-gray-900">Buttons</h4>
              <div className="flex flex-grow items-center justify-center gap-2">
                <button
                  type="button"
                  className={cn(
                    'px-4 py-2 text-white',
                    theme.borderRadius === 'soft' ? 'rounded' : 'rounded-none'
                  )}
                  style={{ backgroundColor: '#2D0A31' }}
                >
                  Button 1
                </button>
                <button
                  type="button"
                  className={cn(
                    'border border-gray-300 px-4 py-2 text-gray-800',
                    theme.borderRadius === 'soft' ? 'rounded' : 'rounded-none'
                  )}
                >
                  Button 2
                </button>
              </div>
            </div>
            <div className="rounded-lg border border-gray-200 p-4 bg-white overflow-hidden">
              <h4 className="mb-4 text-sm font-medium text-gray-900">Images</h4>
              <div className="grid grid-cols-3 gap-2" style={{ width: '120%', marginLeft: '-10%' }}>
                <img
                  src={img1}
                  alt="Fitness equipment"
                  className={cn(
                    'h-28 w-28 object-cover',
                    theme.borderRadius === 'soft' ? 'rounded' : 'rounded-none'
                  )}
                />
                <img
                  src={img2}
                  alt="Person working out"
                  className={cn(
                    'h-28 w-28 object-cover',
                    theme.borderRadius === 'soft' ? 'rounded' : 'rounded-none'
                  )}
                />
                <img
                  src={img3}
                  alt="Fitness equipment"
                  className={cn(
                    'h-28 w-28 object-cover',
                    theme.borderRadius === 'soft' ? 'rounded' : 'rounded-none'
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}

function Favicon() {
  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="rounded-lg border border-gray-100 bg-gray-100 shadow-sm"
    >
      <CollapsibleTrigger className="flex w-full items-center justify-between p-4">
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2 bg-white p-2 rounded-lg">
            <AppWindow className="h-4 w-4 text-black" />
          </div>
          <span className="text-md font-medium">Favicon</span>
        </div>
        <ChevronDown className={cn('h-5 w-5 transition-transform', isOpen ? 'rotate-180' : '')} />
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="p-4 pt-0">
          <div className="rounded-lg border bg-white py-3">
            <div className="flex items-center gap-4 rounded-none  bg-gray-50  p-2">
              <div className="flex items-center gap-1">
                <div className="flex h-6 w-6 items-center justify-center rounded">
                  <Globe className="h-4 w-4 text-gray-500" />
                </div>
                <ChevronLeft className="h-5 w-5 text-gray-500" />
                <ChevronRight className="h-5 w-5 text-gray-500" />
              </div>
              <div className="flex items-center gap-2 px-2 py-0.5 bg-gray-200 rounded w-full">
                <div className="flex h-4 w-4 items-center justify-center rounded bg-[#2D0A31] text-white text-xs">
                  S
                </div>
                <span className="text-sm">sophiecoach.simplysite.io</span>
              </div>
              <div className="ml-auto flex items-center gap-4">
                <ArrowDownCircle className="h-4 w-4 text-gray-500" />

                <Share className="h-4 w-4 text-gray-500" />

                <Plus className="h-4 w-4 text-gray-500" />
              </div>
            </div>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}
