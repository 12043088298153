import React from 'react';
import { useTheme } from './ThemeProvider';
import { colors, fontPairs } from './BrandEditor';
import { Button } from 'src/components/ui/button';
import { Dices } from 'lucide-react';

export default function RollTheDice() {
  const { theme, setTheme } = useTheme();
  const nextThemeCombo = () => {
    const currentIndex = colors.findIndex((c) => theme.colors.main === c.mainBg);
    const fontIndex = fontPairs.findIndex((f) => theme.fonts.heading.name === f.heading.name);

    // if nextindex is out of bounds, go back to the first color
    const nextIndex = (currentIndex + 1) % colors.length;
    const nextFontIndex = (fontIndex + 1) % fontPairs.length;

    setTheme((prev) => ({
      ...prev,
      colors: {
        ...prev.colors,
        main: colors[nextIndex].mainBg,
        secondary: colors[nextIndex].secondaryBg,
      },
      fonts: {
        heading: {
          name: fontPairs[nextFontIndex].heading.name,
          style: fontPairs[nextFontIndex].heading.style,
        },
        body: {
          name: fontPairs[nextFontIndex].body.name,
          style: fontPairs[nextFontIndex].body.style,
        },
      },
    }));
  };
  return (
    <Button size="icon" variant="outline" onClick={nextThemeCombo}>
      <Dices />
    </Button>
  );
}
