// ThemeContext.tsx
import React, { createContext, useContext, useState } from 'react';
import { Theme, defaultTheme } from './theme';

interface ThemeContextProps {
  theme: Theme;
  setTheme: React.Dispatch<React.SetStateAction<Theme>>;
}

const ThemeContext = createContext<ThemeContextProps>({ theme: defaultTheme, setTheme: () => {} });

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({
  initialTheme,
  children,
}: {
  initialTheme: Theme;
  children: React.ReactNode;
}) => {
  const [theme, setTheme] = useState<Theme>(initialTheme);

  const inlineStyle = {
    '--color-main': theme.colors.main,
    '--color-secondary': theme.colors.secondary,
    '--color-black': theme.colors.black,
    '--color-white': theme.colors.white,
    '--font-heading': theme.fonts.heading,
    '--font-body': theme.fonts.body,
    '--border-radius': theme.borderRadius,
  } as React.CSSProperties;
  console.log({ theme, inlineStyle });

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div style={inlineStyle}>{children}</div>
    </ThemeContext.Provider>
  );
};
