import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Lottie, { zenWait } from 'src/components/Lottie';
import { Button } from 'src/components/ui/button';

export default function LeadFormNotSetup() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="h-full w-full inset-0 bg-gray-300 bg-opacity-75 flex items-center justify-center z-50">
      <div className="flex flex-col text-center items-center">
        <div className="flex w-52">
          <Lottie animation={zenWait} />
        </div>
        <h1 className="text-4xl md:text-3xl font-bold text-neutral-700 mb-4">
          {t('leads.custom.empty.headline')}
        </h1>
        <Button onClick={() => navigate('/dashboard/leads/form')}>
          {t('leads.custom.empty.button')}
        </Button>
      </div>
    </div>
  );
}
