import React from 'react';
import { WidgetType } from 'src/enum';
import { selectCurrentPlatformIntegrationsMap } from 'src/redux/selectors/integrations';
import { useSelector } from 'src/redux/store';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';
import CompactCountPreview from './CompactCountPreview';
import MicroStarCountPreview from './MicroStarCountPreview';
import ReviewCardPreview from './ReviewCardPreview';
import VideoCardPreview from './VideoCardPreview';
import TextVideoCardPreview from './TextVideoCardPreview';

/**
 * Review widget settings
 */

export default function WidgetPreviews({ widgetId }: { widgetId: string }) {
  const workspaceId = useSelector(selectCurrentWorkspaceId) as string;
  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  const currentWidget = integrationMap[widgetId];
  const widgetType = currentWidget?.type;
  switch (widgetType) {
    case WidgetType.MICRO_STARS:
      return <MicroStarCountPreview widgetId={widgetId} />;
    case WidgetType.COMPACT_STARS:
      return <CompactCountPreview widgetId={widgetId} />;
    case WidgetType.REVIEW_CARDS:
      return <ReviewCardPreview widgetId={widgetId} />;
    case WidgetType.VIDEO_CARDS:
      return <VideoCardPreview widgetId={widgetId} />;
    case WidgetType.TEXT_VIDEO_CARDS:
      return <TextVideoCardPreview widgetId={widgetId} />;
    // case WidgetType.FLOATING_INTRO_VIDEO:
    //   return <div>Floating Intro Video</div>;
    // case WidgetType.LEAD_FORM:
    //   return <div>Lead Form</div>;
    default:
      console.error(`type=${widgetType}: Invalid widget type`);

      return <div>Invalid widget type</div>;
  }
}
