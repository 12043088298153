import { Button } from '@relume_io/relume-ui';
import type { ButtonProps } from '@relume_io/relume-ui';
import { RxChevronRight } from 'react-icons/rx';
import { useTheme } from '../../ThemeProvider';
import { getContrastColor } from '@simplylabs_/shared';

type ImageProps = {
  src: string;
  alt?: string;
};

type SubHeadingProps = {
  title: string;
  description: string;
  icon: ImageProps;
};

type Props = {
  tagline: string;
  heading: string;
  description: string;
  buttons: ButtonProps[];
  image: ImageProps;
  subHeadings: SubHeadingProps[];
};

export type Layout201Props = React.ComponentPropsWithoutRef<'section'> & Partial<Props>;

export const Layout201 = (props: Layout201Props) => {
  const { theme } = useTheme();
  const backgroundColor = theme.colors.secondary;
  const headingFont = theme.fonts.heading;
  const bodyFont = theme.fonts.body;
  const textColor = getContrastColor(backgroundColor, true);
  const { tagline, heading, description, buttons, image, subHeadings } = {
    ...Layout201Defaults,
    ...props,
  };
  const edgeConfig = theme.borderRadius === 'soft' ? 'rounded-lg' : 'rounded-none';
  console.log({ textColor });

  return (
    <section id="relume" className="px-[5%] py-16 md:py-24 lg:py-28" style={{ backgroundColor }}>
      <div className="container">
        <div className="grid grid-cols-1 items-center gap-12 md:grid-cols-2 lg:gap-x-20">
          <div className="order-2 md:order-1">
            <img
              src={
                'https://images.unsplash.com/photo-1601972599720-36938d4ecd31?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                // image.src
              }
              className={`w-full object-cover ${edgeConfig}`}
              alt={image.alt}
            />
          </div>
          <div className="order-1 md:order-2">
            <p
              className="mb-3 font-semibold md:mb-4"
              style={{
                color: textColor,
                fontFamily: bodyFont.name,
                fontWeight: bodyFont.style,
              }}
            >
              {tagline}
            </p>
            <h2
              className="mb-5 text-5xl font-bold md:mb-6 md:text-7xl lg:text-8xl"
              style={{
                color: textColor,
                fontFamily: headingFont.name,
                fontWeight: headingFont.style,
              }}
            >
              {heading}
            </h2>
            <p
              className="mb-6 md:mb-8 md:text-md"
              style={{
                color: textColor,
                fontFamily: bodyFont.name,
                fontWeight: bodyFont.style,
              }}
            >
              {description}
            </p>
            <div className="grid grid-cols-1 gap-6 py-2 sm:grid-cols-2">
              {subHeadings.map((subHeading, index) => (
                <div key={index}>
                  <div className="mb-3 md:mb-4">
                    <img
                      src={subHeading.icon.src}
                      className={`size-12 ${edgeConfig}`}
                      style={{ filter: textColor === '#FFFFFF' ? 'invert(1)' : 'none' }}
                      alt={subHeading.icon.alt}
                    />
                  </div>
                  <h6
                    className="mb-3 text-md font-bold leading-[1.4] md:mb-4 md:text-xl"
                    style={{
                      color: textColor,
                      fontFamily: headingFont.name,
                      fontWeight: headingFont.style,
                    }}
                  >
                    {subHeading.title}
                  </h6>
                  <p
                    style={{
                      color: textColor,
                      fontFamily: bodyFont.name,
                      fontWeight: bodyFont.style,
                    }}
                  >
                    {subHeading.description}
                  </p>
                </div>
              ))}
            </div>
            <div className="mt-6 flex flex-wrap gap-4 md:mt-8">
              {buttons.map((button, index) => (
                <Button
                  key={index}
                  {...button}
                  style={{
                    color: textColor,
                    fontFamily: bodyFont.name,
                    fontWeight: bodyFont.style,
                  }}
                >
                  {button.title}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const Layout201Defaults: Props = {
  tagline: 'Tagline',
  heading: 'Medium length section heading goes here',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
  buttons: [
    { title: 'Button', variant: 'secondary' },
    {
      title: 'Button',
      variant: 'link',
      size: 'link',
      iconRight: <RxChevronRight />,
    },
  ],
  image: {
    src: 'https://d22po4pjz3o32e.cloudfront.net/placeholder-image.svg',
    alt: 'Relume placeholder image',
  },
  subHeadings: [
    {
      icon: { src: 'https://d22po4pjz3o32e.cloudfront.net/relume-icon.svg', alt: 'Relume logo 1' },
      title: 'Subheading one',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.',
    },
    {
      icon: { src: 'https://d22po4pjz3o32e.cloudfront.net/relume-icon.svg', alt: 'Relume logo 2' },
      title: 'Subheading two',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.',
    },
  ],
};
