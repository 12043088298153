import React from 'react';
import { WidgetType } from 'src/enum';
import { selectCurrentPlatformIntegrationsMap } from 'src/redux/selectors/integrations';
import { useSelector } from 'src/redux/store';
import CompactCountSettings from './CompactCountSettings';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';
import MicroStarCountSettings from './MicroStarsSettings';
import ReviewCardSettings from './ReviewCardSettings';
import VideoCardSettings from './VideoCardSettings';
import TextVideoCardSettings from './TextVideoCardSettings';

/**
 * Review widget settings
 */

export default function WidgetSettings({ widgetId }: { widgetId: string }) {
  const workspaceId = useSelector(selectCurrentWorkspaceId) as string;
  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  const currentWidget = integrationMap[widgetId];
  const widgetType = currentWidget?.type;
  switch (widgetType) {
    case WidgetType.MICRO_STARS:
      return <MicroStarCountSettings workspaceId={workspaceId} widgetId={widgetId} />;
    case WidgetType.COMPACT_STARS:
      return <CompactCountSettings workspaceId={workspaceId} widgetId={widgetId} />;
    // case WidgetType.HEADER_BAR_STARS:
    //   return <div>Header Bar Stars</div>;
    case WidgetType.REVIEW_CARDS:
      return <ReviewCardSettings workspaceId={workspaceId} widgetId={widgetId} />;
    case WidgetType.VIDEO_CARDS:
      return <VideoCardSettings workspaceId={workspaceId} widgetId={widgetId} />;
    case WidgetType.TEXT_VIDEO_CARDS:
      return <TextVideoCardSettings workspaceId={workspaceId} widgetId={widgetId} />;
    // case WidgetType.FLOATING_INTRO_VIDEO:
    //   return <div>Floating Intro Video</div>;
    // case WidgetType.LEAD_FORM:
    //   return <div>Lead Form</div>;
    default:
      console.error(`type=${widgetType}: Invalid widget type`);

      return <div>Invalid widget type</div>;
  }
}
