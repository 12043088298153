'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Settings } from 'lucide-react';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { Button } from 'src/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'src/components/ui/sheet';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/ui/form';
import { Input } from 'src/components/ui/input';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';
import { useSelector } from 'src/redux/store';
import { getCollection } from 'src/api';
import { Collection } from 'src/enum';
import { selectLeadForm } from 'src/redux/slices/leadFormsSlice';

// Define the form schema with Zod
const formSchema = z.object({
  url: z.string().url({
    message: 'Please enter a valid URL.',
  }),
});

type FormValues = z.infer<typeof formSchema>;

export default function SettingsSheet({ leadFormId }: { leadFormId: string }) {
  const { t } = useTranslation();
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const leadForm = useSelector(selectLeadForm);
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // Initialize the form with react-hook-form
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      url: '',
    },
  });

  useEffect(() => {
    if (leadForm?.successUrlRedirect && open) {
      form.setValue('url', leadForm.successUrlRedirect);
    }

    return () => {};
  }, [open, leadForm?.successUrlRedirect]);

  // Define the submit handler
  async function onSubmit(data: FormValues) {
    console.log('Form submitted:', data);
    // You can add your logic to save the URL here
    try {
      setSubmitting(true);
      if (!workspaceId || !leadFormId) {
        throw new Error('No workspaceId or leadFormId');
      }
      await setDoc(
        doc(DB, getCollection[Collection.LEADS_FORMS](workspaceId), leadFormId),
        {
          successUrlRedirect: data.url,
        },
        { merge: true }
      );
      setOpen(false);
      // TODO: create correct translation
      toast.success(t('general.success.default'));
    } catch (error) {
      console.error(error);
      toast.error(t('general.error.default'));
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="outline">
          <Settings className="mr-2 h-4 w-4" />
          Settings
        </Button>
      </SheetTrigger>
      <SheetContent className="sm:max-w-md">
        <SheetHeader>
          <SheetTitle>Settings</SheetTitle>
          <SheetDescription>
            Configure your lead form settings. Click save when you're done.
          </SheetDescription>
        </SheetHeader>
        <div className="py-6">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="url"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Redirect to your custom page when the form is submitted</FormLabel>
                    <FormControl>
                      <Input placeholder="https://example.com" {...field} />
                    </FormControl>
                    <FormDescription>Enter the URL for your success page.</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button loading={submitting} type="submit" className="w-full">
                Save changes
              </Button>
            </form>
          </Form>
        </div>
      </SheetContent>
    </Sheet>
  );
}
