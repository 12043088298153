import { WidgetType } from '../../../enum';
import { useSelector } from 'src/redux/store';
import { SplitScreen } from 'src/components/split-screen';
import WidgetSettings from './WidgetSettings';
import WidgetPreviews from './WidgetPreviews';
import LeadFormNotSetup from './LeadFormNotSetup';

/**
 *
 * @returns Loading all widgets from else where so it will always be present
 */
export default function Widget() {
  // const { widgetId } = useParams<{ widgetId: string }>();

  const currentWidget = useSelector((state) => ({
    ...state.leadForms.list[0],
    type: WidgetType.LEAD_FORM,
  }));
  const widgetId = currentWidget.id;

  const widgetType = currentWidget?.type as WidgetType;

  const notSetup = !widgetId || !widgetType;

  if (notSetup) return <LeadFormNotSetup />;

  return (
    <SplitScreen
      direction="horizontal"
      leftDefaultSize={60}
      leftPanel={
        <div className="flex h-[calc(100vh - 63px)] overflow-y-scroll justify-start p-6">
          <div className="w-full">
            <WidgetSettings widgetId={widgetId as string} widgetType={widgetType} />
          </div>
        </div>
      }
      rightDefaultSize={40}
      rightPanel={
        <div className="flex h-[100vh]">
          <div className="w-full">
            <WidgetPreviews widgetType={widgetType} />
          </div>
        </div>
      }
    />
  );
}
