'use client';

import * as React from 'react';
import { ChevronDown, Eye, EyeOff, Link, File, Mail, GripVertical } from 'lucide-react';
import { cn } from 'src/utils/utils';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
  type DragStartEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Checkbox } from 'src/components/ui/checkbox';
import { Input } from 'src/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { Button } from 'src/components/ui/button';
import { toast } from 'sonner';

type ButtonType = 'link' | 'page' | 'email';

type ButtonItem = {
  id: string;
  type: ButtonType;
  label: string;
  url?: string;
  openInNewTab?: boolean;
  section?: string;
  email?: string;
  isVisible: boolean;
};

const ButtonTypeSelector = ({
  type,
  onChange,
}: {
  type: ButtonType;
  onChange: (type: ButtonType) => void;
}) => (
  <Tabs value={type} onValueChange={(value) => onChange(value as ButtonType)}>
    <TabsList className="grid grid-cols-3 w-32">
      <TabsTrigger value="link" className="p-1">
        <Link className="h-4 w-4" />
      </TabsTrigger>
      <TabsTrigger value="page" className="p-1">
        <File className="h-4 w-4" />
      </TabsTrigger>
      <TabsTrigger value="email" className="p-1">
        <Mail className="h-4 w-4" />
      </TabsTrigger>
    </TabsList>
  </Tabs>
);

const SortableButtonItem = ({
  item,
  isExpanded,
  anyExpanded,
  onToggle,
  onVisibilityChange,
  onOpenInNewTabChange,
  onTypeChange,
  onValueChange,
  isDragging,
}: {
  item: ButtonItem;
  isExpanded: boolean;
  anyExpanded: boolean;
  onToggle: (id: string) => void;
  onVisibilityChange: (id: string, isVisible: boolean) => void;
  onOpenInNewTabChange: (id: string, openInNewTab: boolean) => void;
  onTypeChange: (id: string, type: ButtonType) => void;
  onValueChange: (id: string, key: string, value: string) => void;
  isDragging: boolean;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition: dndTransition,
    isDragging: isSelfDragging,
  } = useSortable({
    id: item.id,
    disabled: anyExpanded,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: dndTransition,
    zIndex: isSelfDragging ? 10 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={cn('border rounded-md bg-white overflow-hidden', isSelfDragging && 'shadow-lg')}
    >
      <div className="flex items-center justify-between px-4 py-3">
        <div className="flex items-center gap-2">
          <span
            className={cn(
              'flex items-center',
              anyExpanded
                ? 'text-gray-300 cursor-not-allowed'
                : 'text-gray-400 cursor-grab touch-none'
            )}
            {...(!anyExpanded ? attributes : {})}
            {...(!anyExpanded ? listeners : {})}
          >
            <GripVertical className="h-4 w-4" />
          </span>
          <div
            role="button"
            className="text-sm font-medium flex items-center gap-1"
            onClick={() => onToggle(item.id)}
          >
            {item.label}
            <span
              className={cn(
                'transition-transform duration-300 ml-1',
                isExpanded ? 'rotate-180' : 'rotate-0'
              )}
            >
              <ChevronDown className="h-4 w-4" />
            </span>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div
            role="button"
            onClick={() => onVisibilityChange(item.id, !item.isVisible)}
            className="text-gray-500"
          >
            {item.isVisible ? <Eye className="h-4 w-4" /> : <EyeOff className="h-4 w-4" />}
          </div>
        </div>
      </div>

      <div
        className={cn(
          'border-t overflow-hidden transition-all duration-300 ease-in-out',
          isExpanded ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0 border-t-0'
        )}
      >
        <div className="p-4 pt-3 space-y-4">
          <ButtonTypeSelector type={item.type} onChange={(type) => onTypeChange(item.id, type)} />

          <Tabs value={item.type} className="w-full">
            <TabsContent value="link" className="mt-0 space-y-4">
              <Input
                placeholder="Button text"
                value={item.label}
                onChange={(e) => onValueChange(item.id, 'label', e.target.value)}
              />
              <Input
                placeholder="Button URL"
                value={item.url || ''}
                onChange={(e) => onValueChange(item.id, 'url', e.target.value)}
              />
              <div className="flex items-center gap-2">
                <Checkbox
                  id={`new-tab-${item.id}`}
                  checked={item.openInNewTab}
                  onCheckedChange={(checked) => onOpenInNewTabChange(item.id, !!checked)}
                />
                <label htmlFor={`new-tab-${item.id}`} className="text-sm">
                  Open in new tab
                </label>
              </div>
            </TabsContent>

            <TabsContent value="page" className="mt-0 space-y-4">
              <Input
                placeholder="Button text"
                value={item.label}
                onChange={(e) => onValueChange(item.id, 'label', e.target.value)}
              />
              <Select
                value={item.section}
                onValueChange={(value) => onValueChange(item.id, 'section', value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Choose page section" />
                </SelectTrigger>
                <SelectContent>
                  <div className="py-2 px-3 text-sm font-medium text-gray-500">
                    Anchor button to...
                  </div>
                  <SelectItem value="hero">Hero Section</SelectItem>
                  <SelectItem value="about">About Section</SelectItem>
                  <SelectItem value="app">App Section</SelectItem>
                  <SelectItem value="pricing">Pricing Section</SelectItem>
                  <SelectItem value="faq">FAQ Section</SelectItem>
                </SelectContent>
              </Select>
            </TabsContent>

            <TabsContent value="email" className="mt-0 space-y-4">
              <Input
                placeholder="Button text"
                value={item.label}
                onChange={(e) => onValueChange(item.id, 'label', e.target.value)}
              />
              <Input
                placeholder="Email address"
                type="email"
                value={item.email || ''}
                onChange={(e) => onValueChange(item.id, 'email', e.target.value)}
              />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default function ButtonConfig() {
  const [isOpen, setIsOpen] = React.useState(true);
  const [expandedItems, setExpandedItems] = React.useState<string[]>([]);
  const [activeId, setActiveId] = React.useState<string | null>(null);
  const [items, setItems] = React.useState<ButtonItem[]>([
    {
      id: 'consultation',
      type: 'link',
      label: 'Book free consultation',
      url: '',
      openInNewTab: true,
      isVisible: true,
    },
    {
      id: 'pricing',
      type: 'page',
      label: 'Pricing',
      section: 'pricing',
      isVisible: true,
    },
    {
      id: 'contact',
      type: 'email',
      label: 'Contact Us',
      email: 'info@example.com',
      isVisible: true,
    },
  ]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  };

  const handleToggle = (id: string) => {
    setExpandedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleCollapseAll = () => {
    setExpandedItems([]);
    toast.success('Drag and drop sorting is now enabled.');
  };

  const handleVisibilityChange = (id: string, isVisible: boolean) => {
    setItems(items.map((item) => (item.id === id ? { ...item, isVisible } : item)));
  };

  const handleOpenInNewTabChange = (id: string, openInNewTab: boolean) => {
    setItems(items.map((item) => (item.id === id ? { ...item, openInNewTab } : item)));
  };

  const handleTypeChange = (id: string, type: ButtonType) => {
    setItems(items.map((item) => (item.id === id ? { ...item, type } : item)));
  };

  const handleValueChange = (id: string, key: string, value: string) => {
    setItems(items.map((item) => (item.id === id ? { ...item, [key]: value } : item)));
  };

  // Check if any items are expanded
  const anyExpanded = expandedItems.length > 0;

  return (
    <div className="w-full max-w-md bg-gray-100 rounded-lg p-4">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium">Buttons</span>
        </div>
        <div role="button" className="text-gray-500" onClick={() => setIsOpen(!isOpen)}>
          <span
            className={cn(
              'block transition-transform duration-300',
              isOpen ? 'rotate-180' : 'rotate-0'
            )}
          >
            <ChevronDown className="h-5 w-5" />
          </span>
        </div>
      </div>

      <div
        className={cn(
          'overflow-hidden transition-all duration-300 ease-in-out',
          isOpen ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'
        )}
      >
        <div
          className={cn(
            'transition-all duration-200 ease-in-out transform',
            anyExpanded ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 absolute'
          )}
        >
          {anyExpanded && (
            <div className="bg-amber-50 text-amber-800 px-3 py-2 rounded-md mb-3 flex items-center justify-between">
              <p className="text-xs">Collapse all items to enable drag and drop sorting.</p>
              <Button
                variant="outline"
                size="sm"
                onClick={handleCollapseAll}
                className="h-7 text-xs bg-amber-100 border-amber-200 hover:bg-amber-200 text-amber-800"
              >
                Collapse All
              </Button>
            </div>
          )}
        </div>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={items.map((item) => item.id)}
            strategy={verticalListSortingStrategy}
          >
            <div className="space-y-2 py-2">
              {items.map((item) => (
                <SortableButtonItem
                  key={item.id}
                  item={item}
                  isExpanded={expandedItems.includes(item.id)}
                  anyExpanded={anyExpanded}
                  onToggle={handleToggle}
                  onVisibilityChange={handleVisibilityChange}
                  onOpenInNewTabChange={handleOpenInNewTabChange}
                  onTypeChange={handleTypeChange}
                  onValueChange={handleValueChange}
                  isDragging={activeId === item.id}
                />
              ))}
            </div>
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}
