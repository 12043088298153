import React, { useState } from 'react';
import { Stack, Box, Typography, Backdrop } from '@mui/material';
import video_placeholder from '../../../../assets/widget_onboarding/widget_video_guide.png';
import { useTheme } from '@mui/material/styles';
import Iconify from '../../../../components/Iconify';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/lazy';
import { Card } from 'src/components/ui/card';

export default function VideoInstructionCard({ tutorialVideo }: { tutorialVideo?: string }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Card
        className="cursor-pointer shadow-none hover:bg-neutral-50 transition-colors duration-150 p-4 "
        onClick={() => setOpen(true)}
      >
        <Stack direction="row" spacing={2}>
          <Stack>
            <Box
              sx={{
                width: 120,
                height: 72,
                backgroundColor: theme.palette.primary.lighter,
                borderRadius: 1,
                alignItems: 'center',
                overflow: 'hidden',
                backgroundImage: `url(${video_placeholder})`,
                backgroundSize: 'cover',
              }}
            />
          </Stack>
          <Stack justifyContent="center">
            <Typography variant="h6">{t('widgets.setup.video.headline')}</Typography>
            <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
              {t('widgets.setup.video.description')}
            </Typography>
          </Stack>
          <Stack flexGrow={1} justifyContent="center" alignItems="flex-end">
            <Iconify
              sx={{ width: 32, height: 32 }}
              icon="material-symbols:arrow-outward-rounded"
              color={theme.palette.primary.main}
            />
          </Stack>
        </Stack>
      </Card>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Stack
          sx={
            {
              /* width: '100%', justifyContent: 'center', alignItems: 'center' */
            }
          }
          onClick={(e) => e.stopPropagation()}
        >
          {tutorialVideo ? (
            <ReactPlayer
              className="react-player"
              width="100%"
              height="100%"
              style={{ height: '100%', borderRadius: 8 }}
              url={tutorialVideo}
              controls
              onError={(e) => console.log('onError', e)}
            />
          ) : (
            <Typography>Video coming soon!</Typography>
          )}
        </Stack>
      </Backdrop>
    </>
  );
}
