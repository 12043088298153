import React, { createContext, useContext, useState, ReactNode, useMemo } from 'react';
import useConversationsExternal from 'src/hooks/useConversationExternal';
import useMessages, { Message } from 'src/hooks/useMessages';
import { useSelector } from 'src/redux/store';

interface SupportChatContextProps {
  isOpen: boolean;
  toggleOpen: () => void;
  messages: Message[];
  conversationId: string | null;
  countUnseenMessages: number;
  latestNonUserMessageTime: number | null;
}

const SupportChatContext = createContext<SupportChatContextProps | undefined>(undefined);

interface SupportChatProviderProps {
  children: ReactNode;
}

export function SupportChatProvider({ children }: SupportChatProviderProps) {
  useConversationsExternal();
  const [isOpen, setIsOpen] = useState(false);
  const currentId = useSelector((state) => state.conversations.currentId);
  const userId = useSelector((state) => state.user.id);
  const lastSeenMessageByUser = useSelector(
    (state) =>
      // @ts-ignore
      state.conversations?.map[currentId]?.participants[userId]?.lastSeenMessageTime
  );
  const { messages, isLoadingMessages, hasErrorMsg } = useMessages(currentId);
  const countUnseenMessages = useMemo(
    () => getCountUnseenMessages(messages, userId, lastSeenMessageByUser),
    [messages?.length, userId, lastSeenMessageByUser]
  );

  const latestNonUserMessageTime = useMemo(() => {
    // copy messages to mutation
    const message = [...messages]
      ?.sort((a, b) => b?.createTime?.getTime() - a?.createTime?.getTime())
      ?.find((message) => message?.sender?.uid !== userId);
    return message ? message?.createTime.getTime() : null;
  }, [messages?.length, userId]);

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <SupportChatContext.Provider
      value={{
        isOpen,
        toggleOpen,
        messages,
        conversationId: currentId,
        countUnseenMessages,
        latestNonUserMessageTime,
      }}
    >
      {children}
    </SupportChatContext.Provider>
  );
}

export function useSupportChat() {
  const context = useContext(SupportChatContext);
  if (context === undefined) {
    throw new Error('useSupportChat must be used within a SupportChatProvider');
  }
  return context;
}

function getCountUnseenMessages(
  messages: Message[],
  userId: string | null,
  lastSeenMessageTime: Date | null
): number {
  // assume you then have seen all messages
  if (!lastSeenMessageTime) {
    return 0; // messages.length;
  }
  if (!userId) return 0;

  const unseenMessages = messages.filter(
    (message) => message.createTime > lastSeenMessageTime && message.sender.uid !== userId
  );
  return unseenMessages.length;
}
