// sections
import EditWidget from './review/EditWidget';
import { useSelector } from 'react-redux';
import { Integration } from 'src/redux/slices/integrationSlice';
import { useParams } from 'react-router-dom';
import { WidgetType } from '../../enum';
import { selectCurrentPlatformIntegrationsMap } from '../../redux/selectors/integrations';
import Page from 'src/components/Page';

/**
 *
 * @returns Loading all widgets from else where so it will always be present
 */
export default function Widget() {
  const { widgetId } = useParams<{ widgetId: string }>();

  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);

  const currentWidget = (widgetId && integrationMap[widgetId]) as Integration;

  const widgetType = currentWidget?.type as WidgetType;

  return (
    <Page title="Widget - setup">
      <EditWidget widgetId={widgetId} widgetType={widgetType} />
    </Page>
  );
}
