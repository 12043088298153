import {
  TextCardsCarouselWidget,
  TextCardsCarouselWidgetProps,
  TextVideoCardsCarouselWidget,
} from '@simplylabs_/shared';
import website from 'src/assets/widget_onboarding/placeholder_web.png';
import { Card } from 'src/components/ui/card';
import { selectCurrentPlatformIntegrationsMap } from 'src/redux/selectors/integrations';
import { useSelector } from 'src/redux/store';
import { dummyTextVideoReviews } from '../../dummyReviews';

export default function TextVideoCardPreview({ widgetId }: { widgetId: string }) {
  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  const widgetConfig = integrationMap[widgetId]?.config as
    | (TextCardsCarouselWidgetProps & { headerTextColor?: string })
    | null;
  const isDarkMode = widgetConfig?.headerTextColor === '#ffffff';
  return (
    <div
      className={`flex h-full justify-center p-6 relative ${
        isDarkMode ? '!bg-neutral-900' : '!bg-neutral-100'
      }`}
      style={{
        background: `url(${website})`,
        // backgroundSize: 'contain',
        filter: isDarkMode ? 'invert(1)' : 'invert(0)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundOrigin: 'content-box',
      }}
    >
      <div className="absolute w-[900px] top-[15%]">
        <div>
          <Card
            className={`px-0 pt-6 pb-2 shadow-none transition-colors duration-1500 ${
              isDarkMode ? 'bg-neutral-900' : 'bg-white'
            }`}
            style={{
              filter: isDarkMode ? 'invert(1)' : 'invert(0)',
              transform: 'scale(0.5)',
            }}
          >
            <TextVideoCardsCarouselWidget
              token={'test-token'}
              // @ts-ignore
              reviews={dummyTextVideoReviews}
              reviewCount={100}
              rating={4.7}
              slug={'simply-review'}
              // @ts-ignore
              hasControls={widgetConfig?.hasControls}
              // @ts-ignore
              headerTextColor={widgetConfig?.headerTextColor}
              // @ts-ignore
              alignment={widgetConfig?.headerAlignment}
              // @ts-ignore
              controlsBackgroundColor={widgetConfig?.textCardBackgroundColor}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}
