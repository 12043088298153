import { ReviewType } from 'src/enum';

export const dummyReviews = [
  {
    id: '1',
    createTime: {
      _seconds: 1616500000,
    },
    consent: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    type: 'TEXT',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
  {
    id: '2',
    createTime: {
      _seconds: 1616500000,
    },
    consent: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    type: 'TEXT',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
  {
    id: '3',
    createTime: {
      _seconds: 1616500000,
    },
    consent: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    type: 'TEXT',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
  {
    id: '4',
    createTime: {
      _seconds: 1616500000,
    },
    consent: {
      _seconds: 1616500000,
    },
    type: 'TEXT',
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
  {
    id: '5',
    createTime: {
      _seconds: 1616500000,
    },
    consent: {
      _seconds: 1616500000,
    },
    type: 'TEXT',
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
];
export const dummyVideoReviews = [
  {
    id: '1',
    type: ReviewType.VIDEO,
    createTime: {
      _seconds: 1616500000,
    },
    consent: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
    videoPlaybackId: 'sRkw202xA4wLgpX6BDymJcAQ5kPq3BoRA501HjFfRKjLE',
  },
  {
    id: '2',
    type: ReviewType.VIDEO,
    createTime: {
      _seconds: 1616500000,
    },
    consent: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'John Doe',
    },
    videoPlaybackId: '1LBJJsDWSjr7eT1ZGF4isb00U5SvIhQ00Ed402vORoU7n8',
  },
  {
    id: '3',
    type: ReviewType.VIDEO,
    createTime: {
      _seconds: 1616500000,
    },
    consent: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
    videoPlaybackId: '01QWV3KJMQmVCU02vIi01o8FjwSNvCuB6by2BMp02AVrZJI',
  },
  {
    id: '4',
    type: ReviewType.VIDEO,
    createTime: {
      _seconds: 1616500000,
    },
    consent: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
    videoPlaybackId: 'yYn01ObPqZdxGXK55e01wIL878H01iG00a3zRgV900NtzFCs',
  },
];
export const dummyTextVideoReviews = [
  {
    id: '1',
    type: ReviewType.VIDEO,
    createTime: {
      _seconds: 1616500000,
    },
    consent: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
    videoPlaybackId: 'sRkw202xA4wLgpX6BDymJcAQ5kPq3BoRA501HjFfRKjLE',
  },
  {
    id: '2',
    createTime: {
      _seconds: 1616500000,
    },
    consent: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'John Doe',
    },
    type: ReviewType.VIDEO,
    videoPlaybackId: '1LBJJsDWSjr7eT1ZGF4isb00U5SvIhQ00Ed402vORoU7n8',
  },
  {
    id: '3',
    createTime: {
      _seconds: 1616500000,
    },
    consent: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
    type: ReviewType.VIDEO,
    videoPlaybackId: '01QWV3KJMQmVCU02vIi01o8FjwSNvCuB6by2BMp02AVrZJI',
  },
  {
    id: '4',
    createTime: {
      _seconds: 1616500000,
    },
    consent: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
    type: 'TEXT',
    videoPlaybackId: 'yYn01ObPqZdxGXK55e01wIL878H01iG00a3zRgV900NtzFCs',
  },
];
