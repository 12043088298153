import * as React from 'react';
import { ChevronDown } from 'lucide-react';

import { Button } from 'src/components/ui/button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'src/components/ui/collapsible';
import { useTranslation } from 'react-i18next';
import { LabelStyle } from './ReviewFormText';

export function CollapsibleQuestions({
  questions,
  reviewerPageLanguage,
}: {
  questions: any[];
  reviewerPageLanguage: string;
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen} className="w-full space-y-2">
      <div className="flex w-full justify-between items-center mb-2">
        <LabelStyle className="flex flex-grow mb-0" style={{ marginBottom: 0 }}>
          {t('review.form.descriptionLabel', { lng: reviewerPageLanguage })}*
        </LabelStyle>
        <div>
          <CollapsibleTrigger asChild>
            <Button
              variant="secondary"
              className="bg-gray-50 hover:bg-gray-100 flex h-full w-full justify-start"
            >
              <div className="flex justify-between items-center space-x-4 px-4 ">
                <h4 className="text-sm">
                  {t('review.form.questionToggle', { lng: reviewerPageLanguage })}
                </h4>
                <ChevronDown className={`h-4 w-4 transition-transform ${isOpen && 'rotate-180'}`} />
                <span className="sr-only">Toggle</span>
              </div>
            </Button>
          </CollapsibleTrigger>
        </div>
      </div>
      <CollapsibleContent className="space-y-2 pb-2">
        {questions.map((q, index) => (
          <div className="relative">
            <div className="absolute top-0 bottom-0 m-auto flex items-center justify-center h-7 w-7 rounded-full bg-white border border-gray-200 text-xs">
              {index + 1}
            </div>
            <div className="bg-gray-50 rounded-md border border-gray-100 ml-4 px-4 pl-6 py-2 text-sm shadow-sm">
              {q.content}
            </div>
          </div>
        ))}
      </CollapsibleContent>
    </Collapsible>
  );
}
