'use client';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from 'src/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from 'src/components/ui/form';
import { Input } from 'src/components/ui/input';
import { Checkbox } from 'src/components/ui/checkbox';
import { toast } from 'sonner';

import * as z from 'zod';
import { UserPlus } from 'lucide-react';
import { Platforms } from 'src/redux/slices/platform';
import { collection, doc, writeBatch } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { getCollection } from 'src/api';
import { Collection } from 'src/enum';
import uuidv4 from 'src/utils/uuidv4';
import { useTranslation } from 'react-i18next';
import { TeamInviteStatus } from 'src/redux/slices/teamInviteSlice';
import { useSelector } from 'src/redux/store';
import { selectCurrentWorkspaceId, selectUserId } from 'src/redux/slices/userSlice';
import SimplyLeadsIcon from 'src/assets/Logo/SimplyLeadsIcon';
import { FLOW, LEADS, REVIEW } from 'src/theme/palette';
import SimplyFlowIcon from 'src/assets/Logo/SimplyFlowIcon';
import SimplyReviewIcon from 'src/assets/Logo/SimplyReviewIcon';

export const profileFormSchema = z.object({
  name: z.string().min(2, {
    message: 'Name must be at least 2 characters.',
  }),
  email: z.string().email({
    message: 'Please enter a valid email address.',
  }),
  review: z.boolean().default(false),
  flow: z.boolean().default(false),
  leads: z.boolean().default(false),
});

export type ProfileFormValues = z.infer<typeof profileFormSchema>;

export function AddTeamMember() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const companyName = useSelector((state) => state.publicConfig.companyName);
  const userId = useSelector(selectUserId);
  const hasCurrentWorskspaceReview = useSelector(
    (state) =>
      state.workspaceReview.workspaceIds.filter((id) => selectCurrentWorkspaceId(state) === id)
        .length > 0
  );
  const hasCurrentWorskspaceFlow = useSelector(
    (state) =>
      state.workspaceFlow.workspaceIds.filter((id) => selectCurrentWorkspaceId(state) === id)
        .length > 0
  );
  const hasCurrentWorskspaceLeads = useSelector(
    (state) =>
      state.workspaceLeads.workspaceIds.filter((id) => selectCurrentWorkspaceId(state) === id)
        .length > 0
  );

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      name: '',
      email: '',
      review: false,
      flow: false,
      leads: false,
    },
  });

  const { reset } = form;

  async function onSubmit(data: ProfileFormValues) {
    // toast.message(
    //   'You submitted the following values:'
    //   //   description: (
    //   //     <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
    //   //       <code className="text-white">{JSON.stringify(data, null, 2)}</code>
    //   //     </pre>
    //   //   ),
    // );
    const platforms = [
      data.review ? Platforms.SIMPLY_REVIEW : null,
      data.flow ? Platforms.SIMPLY_FLOW : null,
      data.leads ? Platforms.SIMPLY_LEADS : null,
    ].filter(Boolean) as Platforms[];
    try {
      // // await new Promise((resolve) => setTimeout(resolve, 500));
      // const inviteType = resolveInviteType(type);
      if (!userId || !workspaceId || platforms.length < 1) {
        return alert(t('general.error.default'));
      }
      const batch = writeBatch(DB);
      const inviteId = uuidv4();
      platforms.forEach((platform) => {
        const payload = {
          inviteId,
          createTime: new Date(),
          email: data.email.toLowerCase(),
          displayName: data.name,
          status: TeamInviteStatus.PENDING,
          platform,
          workspaceId,
          companyName: companyName || 'team',
          invitedBy: userId,
        };

        batch.set(doc(collection(DB, getCollection[Collection.TEAM_INVITES]())), payload);
      });

      await batch.commit();
      toast.success(t('invites.invitesSent'));
      // enqueueSnackbar(t('invites.invitesSent'), { variant: 'success' });
      reset();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <UserPlus />
          Add Team Member
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('team.inviteForm.title')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('invites.form.fields.name')}</FormLabel>
                  <FormControl>
                    <Input placeholder={t('invites.form.fields.name') as string} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('invites.form.fields.email')}</FormLabel>
                  <FormControl>
                    <Input placeholder={t('invites.form.fields.email') as string} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="review"
              render={({ field }) => (
                <FormItem className="relative flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                  <FormControl>
                    <Checkbox
                      disabled={!hasCurrentWorskspaceReview}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>SimplyReview</FormLabel>
                    <FormDescription>
                      Invite the user to join your SimplyReview workspace.
                    </FormDescription>
                  </div>
                  <div className="absolute rounded bg-black h-6 w-6 items-center justify-center flex top-2 right-2">
                    <SimplyReviewIcon width={6} color={REVIEW.light} />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="flow"
              render={({ field }) => (
                <FormItem className="relative flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                  <FormControl>
                    <Checkbox
                      disabled={!hasCurrentWorskspaceFlow}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>SimplyFlow</FormLabel>
                    <FormDescription>
                      Invite the user to join your SimplyFlow workspace.
                    </FormDescription>
                  </div>
                  <div className="absolute rounded bg-black h-6 w-6 items-center justify-center flex top-2 right-2">
                    <SimplyFlowIcon width={8} color={FLOW.light} />
                  </div>
                </FormItem>
              )}
            />
            {/* 
            TODO: ENABLE SIMPLY LEADS TEAM
            <FormField
              control={form.control}
              name="leads"
              render={({ field }) => (
                <FormItem className="relative flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                  <FormControl>
                    <Checkbox
                      disabled={!hasCurrentWorskspaceLeads}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel className="flex items-center gap-1">SimplyLeads</FormLabel>
                    <FormDescription>
                      Invite the user to join your SimplyLeads workspace.
                    </FormDescription>
                  </div>
                  <div className="absolute rounded bg-black h-6 w-6 items-center justify-center flex top-2 right-2">
                    <SimplyLeadsIcon width={12} color={LEADS.light} />
                  </div>
                </FormItem>
              )}
            /> */}
            <DialogFooter>
              <Button type="submit">Send invite</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
