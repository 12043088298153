// components
import Page from '../../../components/Page';
import WidgetSettings from './WidgetSettings';
import { SplitScreen } from 'src/components/split-screen';
import WidgetPreviews from './WidgetPreviews';
import { useParams } from 'react-router';
import { useSelector } from 'src/redux/store';
import { loadingIntegrationsFlowState } from 'src/redux/slices/integrationFlowSlice';
import LoadingScreen from 'src/components/LoadingScreen';

export default function EditWidget() {
  const { widgetId } = useParams<{ widgetId: string }>();
  const loading = useSelector(loadingIntegrationsFlowState);

  if (loading) return <LoadingScreen />;

  return (
    <Page title="General: Widgets">
      <SplitScreen
        direction="horizontal"
        leftDefaultSize={60}
        leftPanel={
          <div className="flex h-[calc(100vh - 63px)] overflow-y-scroll justify-start p-6">
            <div className="w-full">
              <WidgetSettings widgetId={widgetId as string} />
            </div>
          </div>
        }
        rightDefaultSize={40}
        rightPanel={
          <div className="flex h-[100vh]">
            <div className="w-full">
              <WidgetPreviews widgetId={widgetId as string} />
            </div>
          </div>
        }
      />
    </Page>
  );
}
