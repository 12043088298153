import { Fade } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import WidgetActivation from '../../Steps/components/WidgetActivation';
import WidgetCodeBlock from '../../Steps/components/WidgetCodeBlock';
import { ROOT } from 'src/utils/getWidgetCode';
import VideoInstructionCard from '../../Steps/components/VideoInstructionCard';
import SendInstructionCard from '../../Steps/components/SendInstructionCard';
import CustomiseDarkLightContainer from '../../CustomiseDarkLightContainer';

type WidgetSettingsTab = 'install' | 'customise';

export function getCompactCountWidgetCode(workspaceId: string, widgetId: string) {
  return `<script defer src="${ROOT}/compactreviewcount/index.js"></script>
<div data-widget-compactreviewcount="${workspaceId}_${widgetId}"></div>`;
}

export default function CompactCountSettings({
  workspaceId,
  widgetId,
}: {
  workspaceId: string;
  widgetId: string;
}) {
  const { t } = useTranslation();
  const [tab, setTab] = useState<WidgetSettingsTab>('install');
  const isInstallTab = tab === 'install';
  const isCustomiseTab = tab === 'customise';

  const code = getCompactCountWidgetCode(workspaceId, widgetId);

  return (
    <>
      <Tabs
        value={tab}
        // className="w-[400px]"
        onValueChange={(value) => setTab(value as WidgetSettingsTab)}
      >
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="install">{t('widgets.setup.tab.code')}</TabsTrigger>
          <TabsTrigger value="customise">{t('widgets.setup.tab.customise')}</TabsTrigger>
        </TabsList>
      </Tabs>
      <Fade key="slide-widget-code" in={isInstallTab}>
        <div className="pt-8" style={{ display: isInstallTab ? 'block' : 'none' }}>
          <div className="p-2 pb-8">
            <WidgetCodeBlock code={code} />
          </div>
          <div className="p-2">
            <VideoInstructionCard tutorialVideo="https://storage.googleapis.com/simply-review-b6fb2.appspot.com/tutorials/setup-video-widget-short.mov" />
          </div>
          <div className="p-2">
            <SendInstructionCard widgetId={widgetId} />
          </div>
          <div className="p-2 pt-8">
            <WidgetActivation widgetId={widgetId} />
          </div>
        </div>
      </Fade>
      {/* CUSTOMISE */}
      <Fade key="slide-widget-customise" in={isCustomiseTab}>
        <div className="pt-8" style={{ display: isCustomiseTab ? 'block' : 'none' }}>
          {/* <p className="">{t('widgets.setup.customise.headline')}</p> */}
          <CustomiseDarkLightContainer widgetId={widgetId} />
        </div>
      </Fade>
    </>
  );
}
