import React from 'react';
import { WidgetType } from 'src/enum';
import { selectCurrentPlatformIntegrationsMap } from 'src/redux/selectors/integrations';
import { useSelector } from 'src/redux/store';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';
import LeadFormSettings from './LeadsFormSettings';

/**
 * Flow widget settings
 */

export default function WidgetSettings({
  widgetId,
  widgetType,
}: {
  widgetId: string;
  widgetType: WidgetType;
}) {
  const workspaceId = useSelector(selectCurrentWorkspaceId) as string;
  //   const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  //   const currentWidget = integrationMap[widgetId];
  //   const widgetType = currentWidget?.type;
  switch (widgetType) {
    case WidgetType.LEAD_FORM:
      return <LeadFormSettings workspaceId={workspaceId} widgetId={widgetId} />;
    default:
      console.error(`type=${widgetType}: Invalid widget type`);

      return <div>Invalid widget type</div>;
  }
}
