import { Button } from '@relume_io/relume-ui';
import type { ButtonProps } from '@relume_io/relume-ui';
import { RxChevronRight } from 'react-icons/rx';
import { useTheme } from '../../ThemeProvider';
import { getContrastColor } from '@simplylabs_/shared';

type ImageProps = {
  src: string;
  alt?: string;
};

type Props = {
  tagline: string;
  heading: string;
  description: string;
  buttons: ButtonProps[];
  image: ImageProps;
};

export type Layout1Props = React.ComponentPropsWithoutRef<'section'> & Partial<Props>;

export const Layout1 = (props: Layout1Props) => {
  const { theme } = useTheme();
  const backgroundColor = theme.colors.main;
  const headingFont = theme.fonts.heading;
  const bodyFont = theme.fonts.body;
  const textColor = getContrastColor(backgroundColor, true);
  const { tagline, heading, description, buttons, image } = {
    ...Layout1Defaults,
    ...props,
  };
  const edgeConfig = theme.borderRadius === 'soft' ? 'rounded-lg' : 'rounded-none';
  return (
    <section id="relume" className="px-[5%] py-16 md:py-24 lg:py-28" style={{ backgroundColor }}>
      <div className="container">
        <div className="grid grid-cols-1 gap-y-12 md:grid-cols-2 md:items-center md:gap-x-12 lg:gap-x-20">
          <div>
            <p
              className="mb-3 font-semibold md:mb-4"
              style={{
                color: textColor,
                fontFamily: bodyFont.name,
                fontWeight: bodyFont.style,
              }}
            >
              {tagline}
            </p>
            <h1
              className="rb-5 mb-5 text-5xl font-bold md:mb-6 md:text-7xl lg:text-8xl"
              style={{
                color: textColor,
                fontFamily: headingFont.name,
                fontWeight: headingFont.style,
              }}
            >
              {heading}
            </h1>
            <p
              className="md:text-md"
              style={{
                color: textColor,
                fontFamily: bodyFont.name,
                fontWeight: bodyFont.style,
              }}
            >
              {description}
            </p>
            <div className="mt-6 flex flex-wrap items-center gap-4 md:mt-8">
              {buttons.map((button, index) => (
                <Button
                  key={index}
                  {...button}
                  className={edgeConfig}
                  style={{
                    color: textColor,
                    fontFamily: bodyFont.name,
                    fontWeight: bodyFont.style,
                  }}
                >
                  {button.title}
                </Button>
              ))}
            </div>
          </div>
          <div>
            <img
              src={
                'https://images.unsplash.com/photo-1619361728853-2542f3864532?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                // image.src
              }
              className={`w-full object-cover ${edgeConfig}`}
              alt={image.alt}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export const Layout1Defaults: Props = {
  tagline: 'Tagline',
  heading: 'Medium length section heading goes here',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
  buttons: [
    { title: 'Button', variant: 'secondary' },
    {
      title: 'Button',
      variant: 'link',
      size: 'link',
      iconRight: <RxChevronRight />,
    },
  ],
  image: {
    src: 'https://d22po4pjz3o32e.cloudfront.net/placeholder-image.svg',
    alt: 'Relume placeholder image',
  },
};
