// theme.ts

import { colors } from "./BrandEditor";

export type FontStyle = '400' | '500' | '600' | '700' | '800';

export interface Theme {
  colors: {
    main: string;
    secondary: string;
    black: string;
    white: string;
  };
  fonts: {
    heading: { name: string; style: FontStyle };
    body: { name: string; style: FontStyle };
  };
  borderRadius: 'soft' | 'squared';
}

export const defaultTheme: Theme = {
  colors: {
    main: colors[0].mainBg,
    secondary: colors[0].secondaryBg,
    black: '#000000',
    white: '#ffffff',
  },
  fonts: {
    heading: { name: 'Urbanist', style: '400' },
    body: { name: 'Roboto', style: '400' },
  },
  borderRadius: 'soft',
};
