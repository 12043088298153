import React, { useState } from 'react';
import Page from 'src/components/Page';
import { SplitScreen } from 'src/components/split-screen';
import SectionEditor from './SectionEditor';
import Preview from './Preview';
import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { Fade } from '@mui/material';
import DesignSystem from './BrandEditor';
import { ThemeProvider } from './ThemeProvider';
import { defaultTheme } from './theme';
import { Button } from 'src/components/ui/button';
import { Dice1Icon, Dices } from 'lucide-react';
import RollTheDice from './RollTheDice';
import { TabType, useEditor } from './editor-context';

export default function Website() {
  const { tab, setTab } = useEditor();
  const rollTheDice = () => {};

  return (
    <Page
      title="Website"
      // @ts-ignore
      style={{ height: '100%' }}
    >
      <ThemeProvider initialTheme={defaultTheme}>
        <SplitScreen
          direction="horizontal"
          leftDefaultSize={50}
          leftPanel={
            <div
              className="flex h-full p-6"
              style={{ height: 'calc(100vh - 64px)', overflowY: 'scroll' }}
            >
              <div className="flex flex-col w-full gap-8">
                <div className="flex gap-4">
                  <Tabs
                    className="flex-grow"
                    value={tab}
                    onValueChange={(value) => setTab(value as TabType)}
                  >
                    <TabsList className="grid grid-cols-3 w-full h-full">
                      <TabsTrigger value="editor" className="">
                        Content
                      </TabsTrigger>
                      <TabsTrigger value="brand" className="">
                        Brand
                      </TabsTrigger>
                      <TabsTrigger value="settings" className="">
                        Settings
                      </TabsTrigger>
                    </TabsList>
                  </Tabs>
                  <RollTheDice />
                </div>
                <Fade key="editor-sites" in={tab === 'editor'}>
                  <div className={tab === 'editor' ? 'flex flex-col gap-4' : 'hidden'}>
                    <SectionEditor />
                  </div>
                </Fade>
                <Fade key="brand-sites" in={tab === 'brand'}>
                  <div className={tab === 'brand' ? 'flex flex-col gap-4' : 'hidden'}>
                    <DesignSystem />
                  </div>
                </Fade>
              </div>
            </div>
          }
          rightDefaultSize={50}
          rightPanel={<Preview />}
        />
      </ThemeProvider>
    </Page>
  );
}
