import React from 'react';
import { WidgetType } from 'src/enum';
import { selectCurrentPlatformIntegrationsMap } from 'src/redux/selectors/integrations';
import { useSelector } from 'src/redux/store';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';
import FloatingVideoPreview from './FloatingVideoPreview';

/**
 * Review widget settings
 */

export default function WidgetPreviews({ widgetId }: { widgetId: string }) {
  const workspaceId = useSelector(selectCurrentWorkspaceId) as string;
  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  const currentWidget = integrationMap[widgetId];
  const widgetType = currentWidget?.type;
  switch (widgetType) {
    case WidgetType.FLOATING_INTRO_VIDEO:
      return <FloatingVideoPreview widgetId={widgetId} />;
   
    default:
      console.error(`type=${widgetType}: Invalid widget type`);

      return <div>Invalid widget type</div>;
  }
}
