import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import PulseDot from '../../../../components/PulseDot';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'src/redux/store';
import { selectCurrentPlatformIntegrationsMap } from 'src/redux/selectors/integrations';

interface Props {
  widgetId: string;
}

export default function WidgetActivation({ widgetId }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  const currentWidget = integrationMap[widgetId];
  const isActive = currentWidget?.state === 'ACTIVE';
  const url = `${currentWidget?.metadata?.hostname}${currentWidget?.metadata?.pathname}`
  const pulseColor = isActive ? theme.palette.success.main : theme.palette.error.main;
  return (
    <>
      <Box>
        <Typography variant="h6">{t('widgets.setup.activation.headline')}</Typography>
        <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
          {t('widgets.setup.activation.description')}
        </Typography>
      </Box>
      <Box sx={{ py: 4, display: 'flex' }}>
        <PulseDot color={pulseColor} />
        <Typography sx={{ ml: 3 }} variant="body1">
          {isActive
            ? t('widgets.setup.activation.completeText', {
                url,
              })
            : t('widgets.setup.activation.awaitingText')}
        </Typography>
      </Box>
    </>
  );
}
