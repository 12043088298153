import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Page from '../../../components/Page';
import {
  Container,
  Fade,
  Grid,
  Slide,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';
import useSettings from '../../../hooks/useSettings';
import Iconify from '../../../components/Iconify';
import { useSelector } from '../../../redux/store';
import { loadingLeadFormState, selectLeadForm } from '../../../redux/slices/leadFormsSlice';
import { LeadPage, SuccessLeadForm } from 'src/pages/simplyleads/leadPage';
import { Stack } from '@mui/material';
import { Card } from '@mui/material';
import { selectISPublicConfigLoading } from '../../../redux/slices/publicConfig';
import { LoadingButton } from '@mui/lab';
import ColorBlockPicker from '../../../components/colorPicker/ColorBlockPicker';
import { UploadAvatar } from '../../../components/upload';
import { usePrompt } from '../../../hooks/usePrompt';
import { getIntl } from '../../../locales/helper';
import { upload } from '../../../hooks/useUpload';
import { IMAGE_METADATA_TYPE } from '../../../enum';
import { collection, doc, setDoc } from 'firebase/firestore';
import { DB } from '../../../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import PatternSelect from './PatternSelect';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';
import PageContent from 'src/layouts/dashboard/PageContent';
import LeadFormNotSetup from 'src/pages/Integrations/leads/LeadFormNotSetup';

export default function LeadCustomisePageContainer() {
  const isLoading = useSelector(loadingLeadFormState);
  const isLoadingPublicConfig = useSelector(selectISPublicConfigLoading);
  const leadForm = useSelector(selectLeadForm);

  if (isLoading || isLoadingPublicConfig) return null;

  if (!leadForm) return <LeadFormNotSetup />;

  return <LeadCustomisePage />;
}

function LeadCustomisePage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const leadForm = useSelector(selectLeadForm);
  const {
    id,
    color,
    companyPageLogo,
    leadPageLogo,
    leadPageName,
    leadPageColor,
    leadPageSuccessText,
    leadPagePattern,
    companyName,
  } = useSelector((state) => state.publicConfig);
  const { themeStretch } = useSettings();
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const [submitting, setSubmitting] = useState(false);
  const [tab, setTab] = useState(0);
  const initialName = leadPageName || companyName || '';
  const [leadName, setLeadName] = useState(initialName);
  const initialLogo = leadPageLogo?.original || companyPageLogo?.thumbnail || null;
  const [leadLogo, setLeadLogo] = useState<File | string | null>(initialLogo);

  const initialColor = leadPageColor || leadForm?.color || color || theme.palette.primary.main;
  const [hex, setHex] = useState(initialColor);
  const initialPattern = leadPagePattern || null;
  const [pattern, setPattern] = useState<string | null>(initialPattern);
  const initialLeadText =
    leadPageSuccessText ||
    (t('leads.success.default.text', {
      lng: getIntl(leadForm.language),
    }) as string);
  const [successText, setSuccessText] = useState(initialLeadText);
  /**
   * Make sure the all is reset when the initial values after upload and save
   */
  useEffect(() => setLeadLogo(initialLogo), [initialLogo]);
  useEffect(() => setLeadName(initialName), [initialName]);
  useEffect(() => setHex(initialColor), [initialColor]);
  useEffect(() => setSuccessText(initialLeadText), [initialLeadText]);
  useEffect(() => setPattern(initialPattern), [initialPattern]);

  const isDirty = useMemo(
    () =>
      leadName !== initialName ||
      hex !== initialColor ||
      leadLogo !== initialLogo ||
      successText !== initialLeadText ||
      pattern !== initialPattern,
    [
      hex,
      leadName,
      initialName,
      leadLogo,
      initialLogo,
      initialColor,
      successText,
      initialLeadText,
      pattern,
      initialPattern,
    ]
  );

  usePrompt(t('captions.unsaved'), isDirty);
  const handleChange = (_: any, newValue: number) => {
    setTab(newValue);
  };

  const handleLeadNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLeadName(e.target.value);
  };

  const handleLeadLogoChange = useCallback((acceptedFiles: any[]) => {
    const file = acceptedFiles[0];

    if (file) {
      setLeadLogo(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      // setavatar(URL.createObjectURL(file));
    }
  }, []);

  const handleSuccessTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSuccessText(e.target.value);
  };

  const handleSave = async () => {
    try {
      let defaultSavedString = 'general.success.default';
      let logoUrl;
      setSubmitting(true);
      if (!id || !workspaceId) {
        throw new Error('No config id or workspace id');
      }

      if (leadLogo !== initialLogo && leadLogo instanceof File) {
        const response = await upload(
          leadLogo as Blob,
          `workspaceLeads/${workspaceId}/config/${id}/`,
          {
            userId: workspaceId,
            configId: id,
            type: IMAGE_METADATA_TYPE.LEAD_PAGE_LOGO,
          }
        );

        if (response?.url) {
          logoUrl = response.url;
          defaultSavedString = 'general.changes.saved.withLaterUpdates';
        }
      }

      const configRef = doc(collection(DB, 'public'), id);
      if (logoUrl) {
        await setDoc(
          configRef,
          {
            leadPageName: leadName,
            leadPageColor: hex,
            leadPageSuccessText: successText,
            leadPageLogo: {
              thumbnail: logoUrl,
            },
            leadPagePattern: pattern,
          },
          { merge: true }
        );
      } else {
        await setDoc(
          configRef,
          {
            leadPageName: leadName,
            leadPageColor: hex,
            leadPageSuccessText: successText,
            leadPagePattern: pattern,
          },
          { merge: true }
        );
      }
      enqueueSnackbar(t(defaultSavedString), { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('captions.error'), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Page title="Leads" /* height="100%" mr={-2} mb={-14} */>
      <PageContent>
        <Grid container spacing={3} height={'100%'} ml={0}>
          <Grid
            item
            md={5}
            height={'calc(100vh - 64px)'}
            position="relative"
            sx={{ overflowY: 'scroll', paddingLeft: '0px !important' }}
          >
            {/* <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                mb: 5,
                overflowX: 'hidden',
              }}
            >
              <Typography variant="h4">{t('leads.headline.title')}</Typography>
            </Box> */}
            <Tabs value={tab} onChange={handleChange} aria-label="customisation tabs">
              <Tab
                icon={<Iconify fontSize={24} icon="mdi:user-box" />}
                label={t('leads.customise.tab.page')}
              />
              <Tab
                icon={<Iconify fontSize={24} icon="mdi:cards-heart" />}
                label={t('leads.customise.tab.thankyou')}
              />
            </Tabs>
            <Stack pr={6} position="relative" height="80%">
              <Fade key="slide-edit-page" in={tab === 0}>
                <Stack display={tab === 0 ? 'flex' : 'none'} mt={3} spacing={4}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1">{t('general.media.uploadLogo')}</Typography>
                    <UploadAvatar
                      accept={{
                        'image/*': [],
                      }}
                      file={leadLogo}
                      onDrop={handleLeadLogoChange}
                    />
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1">{t('general.edit.name')}</Typography>
                    <TextField value={leadName} onChange={handleLeadNameChange} />
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1">
                      {t('general.colors.backgroundColor')}
                    </Typography>
                    <ColorBlockPicker hex={hex} setHex={setHex} />
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1">
                      {t('general.pattern.backgroundPattern')}
                    </Typography>
                    <PatternSelect color={hex} pattern={pattern} setPattern={setPattern} />
                  </Stack>
                </Stack>
              </Fade>
              <Fade key="slide-edit-thank-you" in={tab === 1}>
                <Stack display={tab === 1 ? 'flex' : 'none'} mt={3} spacing={4}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1">{t('general.edit.text')}</Typography>
                    <TextField value={successText} onChange={handleSuccessTextChange} multiline />
                  </Stack>
                </Stack>
              </Fade>
              <Box flexGrow={1} pb={8} />
              <Stack
                justifyContent={'flex-end'}
                alignItems="flex-end"
                sx={{ position: 'sticky', zIndex: 2, right: 0, bottom: 16 }}
              >
                <Box>
                  <LoadingButton
                    variant="contained"
                    size="large"
                    disabled={!isDirty || submitting}
                    onClick={handleSave}
                  >
                    {t('general.form.saveChanges')}
                  </LoadingButton>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <StyledGridItem
            item
            md={7}
            height={'calc(100vh - 64px)'}
            style={{ paddingLeft: '0px !important' }}
            sx={{
              backgroundColor: 'grey.100',
              paddingLeft: '0px !important',
              pl: 0,
              pt: 0,
              alignItems: 'center',
            }}
          >
            <Slide key="slide-form" direction="up" in={tab === 0}>
              <Stack
                display={tab === 0 ? 'flex' : 'none'}
                height="100%"
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Card key="form" sx={{ overflow: 'hidden', m: 2, width: '90%' }}>
                  <WebsiteBar />
                  <Stack
                    height={700}
                    sx={{
                      transform: 'translate3d(0,0,0)',
                      overflowY: 'scroll',
                      overflow: 'hidden',
                    }}
                  >
                    <LeadPage
                      leadPageName={leadName}
                      // @ts-ignore
                      leadPageLogo={leadLogo?.preview as string}
                      leadPageColor={hex}
                      leadPagePattern={pattern}
                    />
                  </Stack>
                </Card>
              </Stack>
            </Slide>
            <Slide key="slide-thank-you" direction="up" in={tab === 1}>
              <Stack
                display={tab === 1 ? 'flex' : 'none'}
                height="100%"
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Card key="thank-you" sx={{ m: 2, width: '90%' }}>
                  <WebsiteBar />
                  <Stack height={600}>
                    <SuccessLeadForm leadPageColor={hex} successText={successText} />
                  </Stack>
                </Card>
              </Stack>
            </Slide>
          </StyledGridItem>
        </Grid>
      </PageContent>
    </Page>
  );
}

const StyledGridItem = styled(Grid)(({ theme }) => ({
  paddingTop: '0px !important',
  paddingLeft: '0px !important',
}));

function WebsiteBar() {
  const theme = useTheme();
  return (
    <Stack
      sx={{ px: 2, py: 1, backgroundColor: theme.palette.grey[300] }}
      direction="row"
      justifyContent="space-between"
      spacing={2}
    >
      {/* <Typography>New e-mail</Typography> */}
      <Stack direction="row" spacing={1} alignItems="center">
        <Box
          sx={{
            height: 16,
            width: 16,
            borderRadius: '50%',
            backgroundColor: '#E96E4C',
          }}
        />
        <Box
          sx={{
            height: 16,
            width: 16,
            borderRadius: '50%',
            backgroundColor: '#E6A935',
          }}
        />
        <Box
          sx={{
            height: 16,
            width: 16,
            borderRadius: '50%',
            backgroundColor: '#85C33D',
          }}
        />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 2,
          height: 32,
          width: '100%',
          borderRadius: 1,
          backgroundColor: 'common.white',
        }}
      >
        <Box
          sx={{
            height: 12,
            width: '80%',
            borderRadius: 0.25,
            backgroundColor: 'grey.300',
          }}
        />
      </Box>
    </Stack>
  );
}
